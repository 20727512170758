import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { AboutFoundComponent } from './components/about-found/about-found.component';
import { AboutProjectComponent } from './components/about-project/about-project.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { QaComponent } from './components/qa/qa.component';
import { GAEvent, GAEventQueueService, GAEventType } from 'src/app/ga-events';
@Component({
  selector: 'app-project-about',
  standalone: true,
  imports: [
    CommonModule,
    NzDrawerModule,
    NzTabsModule,
    NzButtonModule,
    TranslateModule,
    AboutFoundComponent,
    AboutProjectComponent,
    ReportingComponent,
    QaComponent,
  ],
  templateUrl: './project-about.component.html',
  styleUrls: ['./project-about.component.scss'],
})
export class ProjectAboutComponent {
  public isDrawerVisible = false;

  private _eventQueue = inject(GAEventQueueService)

  public close(): void {
    this.isDrawerVisible = false;
  }

  public open(): void {
    this.isDrawerVisible = true;
    this._eventQueue.dispatch(new GAEvent(GAEventType.ABOUT_US_CLICK, true))
  }
}
