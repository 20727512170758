import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { GoalPanelModule } from '../goal-panel';
import { SoundButtonModule } from '../sound-button';
import { ProjectGoalComponent } from './components/project-goal/project-goal.component';
import { ProjectAboutComponent } from './components/project-about/project-about.component';
import { ProjectStagesComponent } from './components/project-stages/project-stages.component';
import { ProjectProfileComponent } from './components/project-profile/project-profile.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    GoalPanelModule,
    SoundButtonModule,
    ProjectGoalComponent,
    ProjectAboutComponent,
    ProjectStagesComponent,
    ProjectProfileComponent,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
