import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-damage-row',
  templateUrl: './damage-row.component.html',
  styleUrls: ['./damage-row.component.scss'],
  standalone: true,
  host: {
    style: 'width: 100%;', // Apply styles directly to the host element
  },
})
export class DamageRowComponent {
  @Input({ required: true }) percentages: number = 0;

  get percentagesString(): string {
    return `${this.percentages}%`;
  }
}
