<ng-container *ngIf="!isMobile; else mobileView">
  <div class="btn-wrapper" [ngClass]="{ active: isVisible }">
    <button
      nz-button
      nzShape="round"
      class="chat-btn"
      aria-label="Open the donation history pop-up window"
      [(nzPopoverVisible)]="isVisible"
      (nzPopoverVisibleChange)="change($event)"
      nzPopoverPlacement="rightBottom"
      nzPopoverTrigger="click"
      nz-popover
      [nzPopoverTitle]="undefined"
      [nzPopoverContent]="contentTemplate"
      nzPopoverOverlayClassName="chat-popover"
    >
      <svg
        [ngClass]="{ active: isVisible }"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9723 2C13.6765 2.00095 15.3522 2.43794 16.8402 3.26944C18.3282 4.10094 19.579 5.29935 20.4738 6.75081C21.3687 8.20227 21.8778 9.85859 21.9529 11.5624C22.0281 13.2662 21.6666 14.961 20.9029 16.4857L21.9504 20.6751C21.9899 20.8364 21.9865 21.0051 21.9405 21.1646C21.8945 21.3241 21.8075 21.4688 21.6881 21.5841C21.5688 21.6994 21.4213 21.7814 21.2604 21.8218C21.0995 21.8622 20.9308 21.8597 20.7712 21.8145L16.8808 20.7175C15.5496 21.4651 14.0648 21.8976 12.5405 21.9816C11.0163 22.0656 9.49305 21.799 8.08779 21.2021C6.68254 20.6053 5.43263 19.6942 4.43405 18.5386C3.43546 17.3831 2.71475 16.014 2.32724 14.5363C1.93974 13.0587 1.89575 11.5118 2.19865 10.0145C2.50155 8.51717 3.14329 7.10922 4.07459 5.89875C5.00589 4.68827 6.20199 3.70746 7.57106 3.03161C8.94012 2.35577 10.4457 2.00287 11.9723 2ZM17.6957 6.27246C16.6197 5.19548 15.2623 4.44375 13.779 4.10348C12.2957 3.76321 10.7466 3.84817 9.30937 4.34863C7.87212 4.84908 6.6049 5.74476 5.65304 6.93296C4.70118 8.12115 4.10322 9.55375 3.92772 11.0665C3.75222 12.5792 4.0063 14.1108 4.66081 15.4856C5.31532 16.8605 6.34377 18.0229 7.62822 18.8395C8.91267 19.6562 10.4011 20.0941 11.9228 20.1029C13.4446 20.1118 14.938 19.6913 16.2319 18.8897C16.3447 18.8145 16.4724 18.7644 16.6062 18.7428C16.74 18.7212 16.8769 18.7285 17.0077 18.7643L19.7075 19.5407L18.9756 16.611C18.9112 16.3736 18.941 16.1206 19.0586 15.9046C19.3881 15.3109 19.6406 14.6775 19.81 14.0199C20.1578 12.6553 20.1458 11.2238 19.7752 9.86522C19.4046 8.50667 18.6881 7.26762 17.6957 6.26921V6.27246Z"
          fill="#3C4131"
        />
        <path
          d="M8.37964 10.8H16.3796"
          stroke="#3C4131"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M8.37964 14.4H13.1796"
          stroke="#3C4131"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </button>
    <div class="over" (click)="close()"></div>
  </div>
</ng-container>

<ng-template #contentTemplate>
  <div class="chat">
    <div class="chat__head">
      <h5 class="chat__head-title">{{ "chat.title" | translate }}</h5>

      <button class="chat-close" (click)="close()">
        <svg
          _ngcontent-ng-c3923006298=""
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g _ngcontent-ng-c3923006298="" clip-path="url(#clip0_1478_99524)">
            <path
              _ngcontent-ng-c3923006298=""
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.70735 2.7075C4.31683 2.31698 3.68366 2.31698 3.29314 2.7075L2.58603 3.41461C2.19551 3.80513 2.19551 4.4383 2.58603 4.82882L6.87909 9.12189L2.70752 13.2935C2.317 13.684 2.317 14.3171 2.70752 14.7077L3.41423 15.4144C3.80476 15.8049 4.43792 15.8049 4.82845 15.4144L9.00002 11.2428L13.1906 15.4334C13.5811 15.8239 14.2143 15.8239 14.6048 15.4334L15.3119 14.7263C15.7025 14.3358 15.7025 13.7026 15.3119 13.3121L11.1213 9.12149L15.435 4.80784C15.8255 4.41732 15.8255 3.78415 15.435 3.39363L14.7283 2.68692C14.3377 2.29639 13.7046 2.2964 13.3141 2.68692L9.00041 7.00057L4.70735 2.7075Z"
              fill="#8A8A8A"
            ></path>
          </g>
          <defs _ngcontent-ng-c3923006298="">
            <clipPath _ngcontent-ng-c3923006298="" id="clip0_1478_99524">
              <rect
                _ngcontent-ng-c3923006298=""
                width="18"
                height="18"
                fill="white"
                transform="translate(0 18) rotate(-90)"
              ></rect>
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
    <div
      class="chat__body"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="500"
      [scrollWindow]="false"
      (scrolled)="onScroll()"
    >
      <nz-list [nzLoading]="loadingHandler.loading">
        <ng-container *ngFor="let info of transactions">
          <app-damage-row [transaction]="info"></app-damage-row>
        </ng-container>
        <nz-list-empty *ngIf="total === 0"></nz-list-empty>
      </nz-list>
    </div>
    <!-- <div class="chat__footer">
      <button nz-button nzType="primary" (click)="donate()">
        {{ "chat.donateButtonTitle" | translate }}
      </button>
    </div> -->
  </div>
</ng-template>

<ng-template #mobileView>
  <button class="chat-btn" aria-label="Open the donation history bottom sheet" nz-button nzShape="round" (click)="openMobileChat()">
    <svg
      [ngClass]="{ active: isVisible }"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9723 2C13.6765 2.00095 15.3522 2.43794 16.8402 3.26944C18.3282 4.10094 19.579 5.29935 20.4738 6.75081C21.3687 8.20227 21.8778 9.85859 21.9529 11.5624C22.0281 13.2662 21.6666 14.961 20.9029 16.4857L21.9504 20.6751C21.9899 20.8364 21.9865 21.0051 21.9405 21.1646C21.8945 21.3241 21.8075 21.4688 21.6881 21.5841C21.5688 21.6994 21.4213 21.7814 21.2604 21.8218C21.0995 21.8622 20.9308 21.8597 20.7712 21.8145L16.8808 20.7175C15.5496 21.4651 14.0648 21.8976 12.5405 21.9816C11.0163 22.0656 9.49305 21.799 8.08779 21.2021C6.68254 20.6053 5.43263 19.6942 4.43405 18.5386C3.43546 17.3831 2.71475 16.014 2.32724 14.5363C1.93974 13.0587 1.89575 11.5118 2.19865 10.0145C2.50155 8.51717 3.14329 7.10922 4.07459 5.89875C5.00589 4.68827 6.20199 3.70746 7.57106 3.03161C8.94012 2.35577 10.4457 2.00287 11.9723 2ZM17.6957 6.27246C16.6197 5.19548 15.2623 4.44375 13.779 4.10348C12.2957 3.76321 10.7466 3.84817 9.30937 4.34863C7.87212 4.84908 6.6049 5.74476 5.65304 6.93296C4.70118 8.12115 4.10322 9.55375 3.92772 11.0665C3.75222 12.5792 4.0063 14.1108 4.66081 15.4856C5.31532 16.8605 6.34377 18.0229 7.62822 18.8395C8.91267 19.6562 10.4011 20.0941 11.9228 20.1029C13.4446 20.1118 14.938 19.6913 16.2319 18.8897C16.3447 18.8145 16.4724 18.7644 16.6062 18.7428C16.74 18.7212 16.8769 18.7285 17.0077 18.7643L19.7075 19.5407L18.9756 16.611C18.9112 16.3736 18.941 16.1206 19.0586 15.9046C19.3881 15.3109 19.6406 14.6775 19.81 14.0199C20.1578 12.6553 20.1458 11.2238 19.7752 9.86522C19.4046 8.50667 18.6881 7.26762 17.6957 6.26921V6.27246Z"
        fill="#3C4131"
      />
      <path
        d="M8.37964 10.8H16.3796"
        stroke="#3C4131"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M8.37964 14.4H13.1796"
        stroke="#3C4131"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </button>

  <nz-drawer
    [(nzVisible)]="isMobileChatVisible"
    [nzTitle]="undefined"
    nzPlacement="bottom"
    [nzClosable]="false"
    [nzHeight]="'calc(100dvh - 100px)'"
    (nzOnClose)="closeMobileChat()"
  >
    <div *nzDrawerContent>
      <div class="chat draw">
        <div
          class="chat__body"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="500"
          [scrollWindow]="false"
          (scrolled)="onScroll()"
        >
          <nz-list [nzLoading]="loadingHandler.loading">
            <ng-container *ngFor="let info of transactions">
              <app-damage-row [transaction]="info"></app-damage-row>
            </ng-container>
            <nz-list-empty *ngIf="total === 0"></nz-list-empty>
          </nz-list>
        </div>
        <div class="chat__footer">
          <button
            nz-button
            nzType="primary"
            class="chat-damage-btn"
            (click)="donate()"
          >
            {{ "chat.donateButtonTitle" | translate }}
          </button>
          <button class="close-btn" (click)="closeMobileChat()">
            <svg
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.635912 2.98499C0.245388 2.59446 0.245388 1.9613 0.635913 1.57077L1.34302 0.863667C1.73354 0.473142 2.36671 0.473143 2.75723 0.863667L7.00057 5.107L11.2432 0.864382C11.6337 0.473858 12.2669 0.473857 12.6574 0.864381L13.3641 1.57109C13.7546 1.96162 13.7546 2.59478 13.3641 2.98531L7.70725 8.64216C7.31673 9.03268 6.68356 9.03268 6.29304 8.64216L5.58633 7.93545C5.58441 7.93353 5.58251 7.93161 5.58061 7.92968L0.635912 2.98499Z"
                fill="#3C4131"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </nz-drawer>
</ng-template>
