import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  inject,
  Output,
  type OnInit,
  EventEmitter,
} from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import {
  RealtimeService,
  ScreenResolutionService,
  StatisticsService,
  TransactionService,
} from 'src/app/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IRevenue } from 'src/app/shared/models';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CountUpModule } from 'ngx-countup';
import { GAEvent, GAEventQueueService, GAEventType } from 'src/app/ga-events';
import { CollectedComponent } from './components/collected/collected.component';
import { LinkedinComponent } from '../project-about/components/linkedin/linkedin.component';

@UntilDestroy(this)
@Component({
  selector: 'app-project-goal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzIconModule,
    NzButtonModule,
    NzDropDownModule,
    NzCollapseModule,
    NzRateModule,
    NzDrawerModule,
    TranslateModule,
    CountUpModule,
    CollectedComponent,
    LinkedinComponent,
  ],
  templateUrl: './project-goal.component.html',
  styleUrls: ['./project-goal.component.scss'],
})
export class ProjectGoalComponent implements OnInit {
  @Output() public expanded = new EventEmitter<boolean>();

  public tabletMode = false;
  public isDrawerVisible = false;
  public isActive = false;
  public revenue: IRevenue = {
    revenue: 0,
    goal: 0,
    percentage: 0,
    countOfDrones: 0,
  };
  public countUpOptions = {
    startVal: 0,
    duration: 2,
    useEasing: true,
    separator: ' ',
  };

  private elementRef = inject(ElementRef);
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.isActive) {
        this.isActive = false;
        this.expanded.emit(this.isActive);
      }
    }
  }

  get dronProgress(): number {
    if (!this.revenue?.percentage) {
      return 0;
    }

    return this.revenue.percentage / 100;
  }

  private breakpointObserver = inject(BreakpointObserver);
  private _transactionService = inject(TransactionService);
  private _statisticsService = inject(StatisticsService);
  private _realtimeService = inject(RealtimeService);
  private _eventQueue = inject(GAEventQueueService);

  constructor(private _screenResolutionService: ScreenResolutionService) {
    this._screenResolutionService.isTablet$.subscribe((isTablet) => {
      this.tabletMode = isTablet;
    });
  }

  ngOnInit(): void {
    this._fetchRevenue();

    this._realtimeService
      .onFetchRevenue()
      .pipe(untilDestroyed(this))
      .subscribe((response: string) => {
        this.revenue = JSON.parse(response);
      });
  }

  public stateChange(state: boolean): void {
    this.expanded.emit(state);

    if (state) {
      this._eventQueue.dispatch(
        new GAEvent(GAEventType.PROJECT_AIM_CLICK, state)
      );
    }
  }

  public close(): void {
    this.isDrawerVisible = false;
  }

  public open(): void {
    this.isDrawerVisible = true;

    this._eventQueue.dispatch(new GAEvent(GAEventType.PROJECT_AIM_CLICK, true));
  }

  donate(): void {
    this._transactionService.noEffortDonate();
    this._eventQueue.dispatch(new GAEvent(GAEventType.EASY_DONATE_CLICK, true));
  }

  private _fetchRevenue(): void {
    this._statisticsService
      .getRevenue()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.revenue = data;
      });
  }
}
