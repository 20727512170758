import { Component } from '@angular/core';
import { ScreenResolutionService } from '../../services/screen-resolution/screen-resolution.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public tabletMode = false;
  public isGoalExpanded = false;

  constructor(private _screenResolutionService: ScreenResolutionService) {
    this._screenResolutionService.isTablet$.subscribe((isTablet) => {
      this.tabletMode = isTablet;
    });
  }

  public onGoalExpanded(expanded: boolean): void {
    this.isGoalExpanded = expanded;
  }
}
