import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonComponent } from 'ng-zorro-antd/button';

@Component({
  selector: 'app-reporting',
  standalone: true,
  imports: [NzButtonComponent, TranslateModule],
  templateUrl: './reporting.component.html',
  styleUrl: './reporting.component.scss',
})
export class ReportingComponent {
  @Output() onClose = new EventEmitter();

  public close() {
    this.onClose.emit();
  }
}
