import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environment/environment';
import { EEnvironment } from 'src/environment/environment.enum';

@Injectable()
export class LogInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const started = Date.now();
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse && environment.name !== EEnvironment.PRODUCTION) {
          const elapsed = Date.now() - started;
          console.log(
            `%c[LOGGER]: Request for ${req.url.slice(
              req.url.indexOf('/api') + 1
            )} took ${elapsed} ms.`,
            'background: #222; color: #bada55'
          );
        }
      })
    );
  }
}
