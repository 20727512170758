import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreferencesBarComponent } from './preferences-bar.component';
import { SoundButtonModule } from '../sound-button';
import { ChatComponent } from '../chat/chat.component';
import { FilterComponent } from '../filter/filter.component';
import { ZoomControlComponent } from '../zoom-control/zoom-control.component';
import { LanguageSelectorComponent } from '../language-selector';
import { TelegramBtnComponent } from '../telegram-btn';

@NgModule({
  declarations: [PreferencesBarComponent],
  imports: [
    CommonModule,
    SoundButtonModule,
    ChatComponent,
    FilterComponent,
    TelegramBtnComponent,
    ZoomControlComponent,
    LanguageSelectorComponent,
  ],
  exports: [PreferencesBarComponent],
})
export class PreferencesBarModule {}
