import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-linkedin',
  standalone: true,
  templateUrl: './linkedin.component.html',
  styleUrl: './linkedin.component.scss',
})
export class LinkedinComponent {
  @Output() onClose = new EventEmitter();

  public close() {
    this.onClose.emit();
  }
}
