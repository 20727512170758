import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environment/environment';
import { IObjectsDamage, IPhasesDamageList } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(private _http: HttpClient) {}

  getObjectsDamage(): Observable<IObjectsDamage> {
    return this._http.get<IObjectsDamage>(
      `${env.apiGateway}/v1/statistics/objects-damage`
    );
  }

  getObjectDamageById(id: string): Observable<any> {
    return this._http.get<any>(`${env.apiGateway}/v1/statistics/objects-damage/${id}`);
  }

  getPhasesDamage(): Observable<IPhasesDamageList> {
    return this._http.get<IPhasesDamageList>(`${env.apiGateway}/v1/statistics/phases-damage`);
  }

  getRevenue(): Observable<any> {
    return this._http.get<any>(`${env.apiGateway}/v1/statistics/revenue`);
  }
}
