import { Language, LiqPayLanguage } from '../enums';
// @ts-expect-error No types :(
import { MaptilerLanguage } from '@maptiler/leaflet-maptilersdk';

export const languageMapping: Record<Language, string> = {
  [Language.ENGLISH]: MaptilerLanguage.ENGLISH,
  [Language.POLISH]: MaptilerLanguage.POLISH,
  [Language.UKRAINIAN]: MaptilerLanguage.UKRAINIAN,
};

/**
 * Checks if a string is a language string.
 *
 * @param {string} str - The value to check for language string.
 * @returns {boolean} `true` if the value is a language string, otherwise `false`.
 */
export const isLanguageString = (str: string): str is Language => {
  // @ts-expect-error
  return Object.values(Language).includes(str);
};

export const liqPayLanguageMapping: Record<Language, LiqPayLanguage> = {
  [Language.ENGLISH]: LiqPayLanguage.ENGLISH,
  [Language.POLISH]: LiqPayLanguage.ENGLISH, // No support for polish :(
  [Language.UKRAINIAN]: LiqPayLanguage.UKRAINIAN,
};

export const rfc5646LanguageMapping: Record<Language, string[]> = {
  [Language.ENGLISH]: [
    'en',
    'en-AU',
    'en-BZ',
    'en-CA',
    'en-CB',
    'en-GB',
    'en-IE',
    'en-JM',
    'en-NZ',
    'en-PH',
    'en-TT',
    'en-US',
    'en-ZA',
    'en-ZW',
  ],
  [Language.POLISH]: ['pl', 'pl-PL'],
  [Language.UKRAINIAN]: ['uk', 'uk-UA'],
};
