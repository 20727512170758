<div class="qa">
  <div class="qa__body">
    <section class="qa__body-qa" *ngFor="let item of items">
      <div class="qa__body-qa-question">
        {{ "projectAbout.qa.body.list." + item + ".question" | translate }}
      </div>
      <div class="qa__body-qa-answer">
        — {{ "projectAbout.qa.body.list." + item + ".answer" | translate }}
        <app-linkedin *ngIf="item > 2 && item < 6"></app-linkedin>
      </div>
    </section>
  </div>
  <div class="qa__footer">
    <button nz-button nzType="primary" (click)="close()">
      {{ "projectAbout.backToMapButtonTitle" | translate }}
    </button>
  </div>
</div>
