<div class="collected">
  <h5>{{ "projectGoal.collected.title" | translate }}</h5>
  <span>
    <strong
      [countUp]="revenue"
      [options]="countUpOptions"
      [reanimateOnClick]="false"
    >
      {{ revenue | number : "1.0-0" : "fr-FR" }}
    </strong>
    &nbsp;/&nbsp;
    {{ goal | number : "1.0-0" : "fr-FR" }}₴
  </span>
</div>
