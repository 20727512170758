<ng-container *ngIf="!tabletMode; else tablet">
  <nz-collapse>
    <nz-collapse-panel
      [nzHeader]="header"
      [(nzActive)]="isActive"
      (nzActiveChange)="stateChange($event)"
    >
      <div class="goal-content__content">
        <!-- <div class="goal-banner">
          <img src="/assets/g-banner.webp" draggable="false" alt="Banner" />
        </div> -->

        <div class="goal-text-block">
          <p>
            {{ "projectGoal.paragraphs.0.content" | translate }}
          </p>
          <p>
            {{ "projectGoal.paragraphs.1.content" | translate }}
          </p>
        </div>

        <p class="goal-tip">
          {{ "projectGoal.goalTip" | translate }}
        </p>

        <div class="goal-text-block">
          <p>
            {{ "projectGoal.paragraphs.2.content" | translate }}
          </p>
          <p>
            {{ "projectGoal.paragraphs.3.content" | translate }}
          </p>
          <p>
            {{ "projectGoal.paragraphs.4.content" | translate }}
          </p>
          <p>
            {{ "projectGoal.paragraphs.5.content" | translate }}
          </p>
          <p>
            {{ "projectGoal.paragraphs.6.content" | translate }}
          </p>
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</ng-container>

<ng-template #header>
  <div class="project-goal">
    <img
      src="/assets/icons/arrow-down.svg"
      role="button"
      class="project-goal__arrow"
      width="18"
      height="18"
      alt="Expand"
      [ngClass]="{ active: isActive }"
    />

    <div class="project-goal__wrapper">
      <div class="project-goal__info">
        <div class="project-goal__info-head">
          <h5>{{ "projectGoal.title" | translate }}</h5>

          <div>
            <strong>
              {{ revenue.countOfDrones | number : "1.0-0" : "fr-FR" }}
            </strong>
            <img
              src="/assets/icons/dron.svg"
              width="18"
              height="18"
              alt="Drone"
            />
          </div>
        </div>

        <p class="project-goal__info-message">
          {{ "projectGoal.subTitle" | translate }}
        </p>
        <nz-rate
          class="project-goal__info-stats"
          [nzCharacter]="characterIcon"
          [ngModel]="dronProgress"
          [nzCount]="10"
          [nzAllowHalf]="true"
          [nzAllowClear]="false"
          [nzDisabled]="true"
        ></nz-rate>
      </div>
      <div class="project-goal__divider"></div>
      <div class="project-goal__collected">
        <app-collected
          [revenue]="revenue.revenue"
          [goal]="revenue.goal"
        ></app-collected>

        <button
          nz-button
          nzType="primary"
          class="project-goal__collected-btn"
          (click)="$event.stopPropagation(); donate()"
        >
          {{ "projectGoal.effortlessDonateButtonTitle" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tablet>
  <div class="mobile-goal">
    <button class="mobile-goal__arrow" (click)="open()">
      <img
        src="/assets/icons/arrow-down.svg"
        width="18"
        height="18"
        alt="Arrow"
      />
    </button>
    <app-collected
      [revenue]="revenue.revenue"
      [goal]="revenue.goal"
    ></app-collected>
    <button
      nz-button
      nzType="primary"
      class="mobile-goal__action"
      (click)="donate()"
    >
      {{ "projectGoal.effortlessDonateButtonTitle" | translate }}
    </button>
  </div>
</ng-template>

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="isDrawerVisible"
  nzPlacement="bottom"
  [nzHeight]="'calc(100dvh - 78px)'"
  [nzTitle]="undefined"
  (nzOnClose)="close()"
>
  <ng-container *nzDrawerContent>
    <div class="goal-drawer">
      <div class="project-goal__info">
        <div class="project-goal__info-head">
          <h5>{{ "projectGoal.title" | translate }}</h5>

          <div>
            <strong>{{
              revenue.countOfDrones | number : "1.0-0" : "fr-FR"
            }}</strong>
            <img
              src="/assets/icons/dron.svg"
              width="18"
              height="18"
              alt="Dron"
            />
          </div>
        </div>
        <p class="project-goal__info-message">
          {{ "projectGoal.subTitle" | translate }}
        </p>
        <div class="project-goal__info-stats">
          <nz-rate
            [nzCharacter]="characterIcon"
            [ngModel]="dronProgress"
            [nzCount]="10"
            [nzAllowHalf]="true"
            [nzAllowClear]="false"
            [nzDisabled]="true"
          ></nz-rate>
        </div>
      </div>
      <div class="goal-drawer__body">
        <div class="goal-content__content">
          <!-- <div class="goal-banner">
            <img src="/assets/g-banner.webp" draggable="false" alt="Banner" />
          </div> -->

          <div class="goal-text-block">
            <p>
              {{ "projectGoal.paragraphs.0.content" | translate }}
            </p>
            <p>
              {{ "projectGoal.paragraphs.1.content" | translate }}
            </p>
          </div>

          <p class="goal-tip">
            {{ "projectGoal.goalTip" | translate }}
          </p>

          <div class="goal-text-block">
            <p>
              {{ "projectGoal.paragraphs.2.content" | translate }}
              <app-linkedin></app-linkedin>
            </p>
            <p>
              {{ "projectGoal.paragraphs.3.content" | translate }}
              <app-linkedin></app-linkedin>
            </p>
            <p>
              {{ "projectGoal.paragraphs.4.content" | translate }}
              <app-linkedin></app-linkedin>
            </p>
            <p>
              {{ "projectGoal.paragraphs.5.content" | translate }}
              <app-linkedin></app-linkedin>
            </p>
            <p>
              {{ "projectGoal.paragraphs.6.content" | translate }}
              <app-linkedin></app-linkedin>
            </p>
          </div>
        </div>
      </div>

      <div class="project-goal__collected">
        <app-collected
          [revenue]="revenue.revenue"
          [goal]="revenue.goal"
        ></app-collected>

        <button
          nz-button
          nzType="primary"
          class="project-goal__collected-btn"
          (click)="donate()"
        >
          {{ "projectGoal.effortlessDonateButtonTitle" | translate }}
        </button>

        <button class="goal-drawer__arrow" (click)="close()">
          <img
            src="/assets/icons/arrow-down.svg"
            width="18"
            height="18"
            alt="Arrow"
          />
        </button>
      </div>
    </div>
  </ng-container>
</nz-drawer>
<ng-template #characterIcon>
  <img src="/assets/icons/dron.svg" width="18" height="18" alt="Drone" />
</ng-template>
