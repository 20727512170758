<div class="damage-type-filters">
  <h6>{{ "damageTypeFilters.title" | translate }}</h6>
  <nz-list class="damage-type-filters__list">
    <ng-container *ngFor="let damageType of damageTypeFilters; let i = index">
      <app-damage-type-switch
        [checked]="damageType.value"
        (checkedChange)="change($event, i)"
        >{{ damageType.label | translate }}</app-damage-type-switch
      >
    </ng-container>
  </nz-list>
</div>
