<ng-container *ngIf="!tabletMode; else tablet">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="header" [(nzActive)]="isActive" (nzActiveChange)="stateChange($event)">
      <div class="stages-list">
        <div class="stages-list__item">
          <div class="stages-list__item-progress">
            <nz-progress
              [nzPercent]="phases?.first?.percentages | number : '1.0-1'"
              [nzStrokeWidth]="progressStrokeWidth"
              nzType="circle"
              [nzWidth]="progressCircleWidth"
            ></nz-progress>
          </div>
          <div class="stages-list__item-info">
            <h6 class="stages-list__item-title">
              {{ "projectStages.stages.0.title.full" | translate }}
            </h6>
            <p class="stages-list__item-description">
              {{ "projectStages.stages.0.description" | translate }}
            </p>
            <div class="stages-list__item-gallery">
              <div class="stages-list__item-gallery-item">
                <img src="/assets/stage-1-img-1.svg" alt="Facility icon" />
              </div>
              <div class="stages-list__item-gallery-item">
                <img src="/assets/stage-1-img-2.svg" alt="Facility icon" />
              </div>
              <div class="stages-list__item-gallery-item">
                <img src="/assets/stage-1-img-3.svg " alt="Facility icon" />
              </div>
            </div>
          </div>
        </div>
        <div class="stages-list__item">
          <div class="stages-list__item-progress">
            <nz-progress
              [nzPercent]="phases?.second?.percentages | number : '1.0-1'"
              [nzStrokeWidth]="progressStrokeWidth"
              nzType="circle"
              [nzWidth]="progressCircleWidth"
              [nzFormat]="lock"
            ></nz-progress>
          </div>
          <div class="stages-list__item-info">
            <h6 class="stages-list__item-title">
              {{ "projectStages.stages.1.title.full" | translate }}
            </h6>
            <p class="stages-list__item-description">
              {{ "projectStages.stages.1.description" | translate }}
            </p>
            <div class="stages-list__item-gallery">
              <div class="stages-list__item-gallery-item">
                <img src="/assets/stage-2-img-1.svg" alt="Facility icon" />
              </div>
              <div class="stages-list__item-gallery-item">
                <img src="/assets/stage-2-img-2.svg" alt="Facility icon" />
              </div>
              <div class="stages-list__item-gallery-item">
                <img src="/assets/stage-2-img-3.svg " alt="Facility icon" />
              </div>
            </div>
          </div>
        </div>
        <div class="stages-list__item">
          <div class="stages-list__item-progress">
            <nz-progress
              [nzPercent]="phases?.third?.percentages | number : '1.0-1'"
              [nzStrokeWidth]="progressStrokeWidth"
              nzType="circle"
              [nzWidth]="progressCircleWidth"
              [nzFormat]="lock"
            ></nz-progress>
          </div>
          <div class="stages-list__item-info">
            <h6 class="stages-list__item-title">
              {{ "projectStages.stages.2.title.full" | translate }}
            </h6>
            <p class="stages-list__item-description">
              {{ "projectStages.stages.2.description" | translate }}
            </p>
            <div class="stages-list__item-gallery">
              <div class="stages-list__item-gallery-item">
                <img
                  src="/assets/stage-img-lock.svg"
                  class="lock"
                  alt="Facility icon"
                />
              </div>
              <div class="stages-list__item-gallery-item">
                <img
                  src="/assets/stage-img-lock.svg"
                  class="lock"
                  alt="Facility icon"
                />
              </div>
              <div class="stages-list__item-gallery-item">
                <img
                  src="/assets/stage-img-lock.svg"
                  class="lock"
                  alt="Facility icon"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="stages-list__item">
          <div class="stages-list__item-progress">
            <nz-progress
              [nzPercent]="phases?.fourth?.percentages | number : '1.0-1'"
              [nzStrokeWidth]="progressStrokeWidth"
              nzType="circle"
              [nzWidth]="progressCircleWidth"
              [nzFormat]="lock"
            ></nz-progress>
          </div>
          <div class="stages-list__item-info">
            <h6 class="stages-list__item-title">
              {{ "projectStages.stages.3.title.full" | translate }}
            </h6>
            <p class="stages-list__item-description">
              {{ "projectStages.stages.3.description" | translate }}
            </p>
            <div class="stages-list__item-gallery">
              <div class="stages-list__item-gallery-item">
                <img
                  src="/assets/stage-img-lock.svg"
                  class="lock"
                  alt="Facility icon"
                />
              </div>
              <div class="stages-list__item-gallery-item">
                <img
                  src="/assets/stage-img-lock.svg"
                  class="lock"
                  alt="Facility icon"
                />
              </div>
              <div class="stages-list__item-gallery-item">
                <img
                  src="/assets/stage-img-lock.svg"
                  class="lock"
                  alt="Facility icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</ng-container>
<ng-template #header>
  <div class="project-stages">
    <img
      src="/assets/icons/arrow-down.svg"
      class="project-stages__arrow"
      width="18"
      height="18"
      alt="Expand"
      [ngClass]="{ active: isActive }"
    />
    <div class="project-stages__wrapper">
      <div class="project-stages__head">
        <h4>{{ "projectStages.title" | translate }}</h4>
        <strong>{{ phases?.first?.percentages | number : "1.0-1" }}%</strong>
      </div>

      <div class="project-stages__list">
        <div class="project-stages__item active">
          <nz-progress
            [nzPercent]="phases?.first?.percentages | number : '1.0-1'"
            [nzStrokeWidth]="progressStrokeWidth"
            nzType="circle"
            [nzWidth]="progressCircleWidth"
          ></nz-progress>
          <h6 class="project-stages__item-title">
            {{ "projectStages.stages.0.title.short" | translate }}
          </h6>
        </div>
        <div class="project-stages__item">
          <nz-progress
            [nzPercent]="phases?.second?.percentages | number : '1.0-1'"
            [nzStrokeWidth]="progressStrokeWidth"
            nzType="circle"
            [nzWidth]="progressCircleWidth"
            [nzFormat]="lock"
          ></nz-progress>
          <h6 class="project-stages__item-title">
            {{ "projectStages.stages.1.title.short" | translate }}
          </h6>
        </div>
        <div class="project-stages__item">
          <nz-progress
            [nzPercent]="phases?.third?.percentages | number : '1.0-1'"
            [nzStrokeWidth]="progressStrokeWidth"
            nzType="circle"
            [nzWidth]="progressCircleWidth"
            [nzFormat]="lock"
          ></nz-progress>
          <h6 class="project-stages__item-title">
            {{ "projectStages.stages.2.title.short" | translate }}
          </h6>
        </div>
        <div class="project-stages__item">
          <nz-progress
            [nzPercent]="phases?.fourth?.percentages | number : '1.0-1'"
            [nzStrokeWidth]="progressStrokeWidth"
            nzType="circle"
            [nzWidth]="progressCircleWidth"
            [nzFormat]="lock"
          ></nz-progress>
          <h6 class="project-stages__item-title">
            {{ "projectStages.stages.3.title.short" | translate }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #lock>
  <img src="/assets/icons/lock.png" width="18" alt="lock" />
</ng-template>

<ng-template #tablet>
  <div class="mobile-stages" (click)="open()">
    <div class="project-stages__wrapper">
      <div class="project-stages__list">
        <div class="project-stages__item active">
          <nz-progress
            [nzPercent]="phases?.first?.percentages | number : '1.0-1'"
            [nzStrokeWidth]="progressStrokeWidth"
            class="white-progress"
            nzType="circle"
            [nzWidth]="65"
          ></nz-progress>
          <h6 class="project-stages__item-title white-progress__title">
            {{ "projectStages.stages.0.title.short" | translate }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="isDrawerVisible"
  nzPlacement="bottom"
  [nzHeight]="'calc(100% - 100px)'"
  [nzTitle]="undefined"
  (nzOnClose)="close()"
>
  <ng-container *nzDrawerContent>
    <div class="stages-drawer">
      <div class="stages-drawer__body">
        <div class="stages-list">
          <div class="stages-list__item">
            <div class="stages-list__item-progress">
              <nz-progress
                [nzPercent]="phases?.first?.percentages | number : '1.0-1'"
                [nzStrokeWidth]="progressStrokeWidth"
                nzType="circle"
                [nzWidth]="progressCircleWidth"
              ></nz-progress>
            </div>
            <div class="stages-list__item-info">
              <h6 class="stages-list__item-title">
                {{ "projectStages.stages.0.title.full" | translate }}
              </h6>
              <p class="stages-list__item-description">
                {{ "projectStages.stages.0.description" | translate }}
              </p>
              <div class="stages-list__item-gallery">
                <div class="stages-list__item-gallery-item">
                  <img src="/assets/stage-1-img-1.svg" alt="Facility icon" />
                </div>
                <div class="stages-list__item-gallery-item">
                  <img src="/assets/stage-1-img-2.svg" alt="Facility icon" />
                </div>
                <div class="stages-list__item-gallery-item">
                  <img src="/assets/stage-1-img-3.svg " alt="Facility icon" />
                </div>
              </div>
            </div>
          </div>
          <div class="stages-list__item">
            <div class="stages-list__item-progress">
              <nz-progress
                [nzPercent]="phases?.second?.percentages | number : '1.0-1'"
                [nzStrokeWidth]="progressStrokeWidth"
                nzType="circle"
                [nzWidth]="progressCircleWidth"
                [nzFormat]="lock"
              ></nz-progress>
            </div>
            <div class="stages-list__item-info">
              <h6 class="stages-list__item-title">
                {{ "projectStages.stages.1.title.full" | translate }}
              </h6>
              <p class="stages-list__item-description">
                {{ "projectStages.stages.1.description" | translate }}
              </p>
              <div class="stages-list__item-gallery">
                <div class="stages-list__item-gallery-item">
                  <img src="/assets/stage-2-img-1.svg" alt="Facility icon" />
                </div>
                <div class="stages-list__item-gallery-item">
                  <img src="/assets/stage-2-img-2.svg" alt="Facility icon" />
                </div>
                <div class="stages-list__item-gallery-item">
                  <img src="/assets/stage-2-img-3.svg " alt="Facility icon" />
                </div>
              </div>
            </div>
          </div>
          <div class="stages-list__item">
            <div class="stages-list__item-progress">
              <nz-progress
                [nzPercent]="phases?.third?.percentages | number : '1.0-1'"
                [nzStrokeWidth]="progressStrokeWidth"
                nzType="circle"
                [nzWidth]="progressCircleWidth"
                [nzFormat]="lock"
              ></nz-progress>
            </div>
            <div class="stages-list__item-info">
              <h6 class="stages-list__item-title">
                {{ "projectStages.stages.2.title.full" | translate }}
              </h6>
              <p class="stages-list__item-description">
                {{ "projectStages.stages.2.description" | translate }}
              </p>
              <div class="stages-list__item-gallery">
                <div class="stages-list__item-gallery-item">
                  <img
                    src="/assets/stage-img-lock.svg"
                    class="lock"
                    alt="Facility icon"
                  />
                </div>
                <div class="stages-list__item-gallery-item">
                  <img
                    src="/assets/stage-img-lock.svg"
                    class="lock"
                    alt="Facility icon"
                  />
                </div>
                <div class="stages-list__item-gallery-item">
                  <img
                    src="/assets/stage-img-lock.svg"
                    class="lock"
                    alt="Facility icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="stages-list__item">
            <div class="stages-list__item-progress">
              <nz-progress
                [nzPercent]="phases?.fourth?.percentages | number : '1.0-1'"
                [nzStrokeWidth]="progressStrokeWidth"
                nzType="circle"
                [nzWidth]="progressCircleWidth"
                [nzFormat]="lock"
              ></nz-progress>
            </div>
            <div class="stages-list__item-info">
              <h6 class="stages-list__item-title">
                {{ "projectStages.stages.3.title.full" | translate }}
              </h6>
              <p class="stages-list__item-description">
                {{ "projectStages.stages.3.description" | translate }}
              </p>
              <div class="stages-list__item-gallery">
                <div class="stages-list__item-gallery-item">
                  <img
                    src="/assets/stage-img-lock.svg"
                    class="lock"
                    alt="Facility icon"
                  />
                </div>
                <div class="stages-list__item-gallery-item">
                  <img
                    src="/assets/stage-img-lock.svg"
                    class="lock"
                    alt="Facility icon"
                  />
                </div>
                <div class="stages-list__item-gallery-item">
                  <img
                    src="/assets/stage-img-lock.svg"
                    class="lock"
                    alt="Facility icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stages-drawer__footer">
        <button
          nz-button
          nzType="primary"
          class="project-states__action"
          (click)="donate()"
        >
          {{ "projectStages.donateButtonTitle" | translate }}
        </button>
        <button class="stages-drawer__arrow" (click)="close()">
          <img
            src="/assets/icons/arrow-down.svg"
            width="18"
            height="18"
            alt="Arrow"
          />
        </button>
      </div>
    </div>
  </ng-container>
</nz-drawer>
