<fieldset class="object-type-filter">
  <app-switch [(checked)]="value" (checkedChange)="toggleSwitch()"></app-switch>
  <img
    src="{{ iconSrc }}"
    alt="Object type filter icon"
    class="object-type-filter__icon"
  />
  <div class="object-type-filter__details">
    <div class="flex-between">
      <span class="object-type-filter__details-label">
        {{ label | translate }}
      </span>
      <span class="object-type-filter__details-summary">
        <span class="object-type-filter__details-summary-destroyed">
          {{ destroyed }}
        </span>
        <span class="object-type-filter__details-summary-separator">/</span>
        <span class="object-type-filter__details-summary-total">
          {{ total }}
        </span>
      </span>
    </div>
    <div class="flex-between">
      <app-damage-row [percentages]="percentages"></app-damage-row>
      <span class="object-type-filter__details-percentage">
        {{ destroyedPercentage }}
      </span>
    </div>
  </div>
</fieldset>
