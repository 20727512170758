import { Component, inject } from '@angular/core';
import { SoundService } from '../../services';
import { GAEvent, GAEventQueueService, GAEventType } from 'src/app/ga-events';

@Component({
  selector: 'app-sound-button',
  templateUrl: './sound-button.component.html',
  styleUrls: ['./sound-button.component.scss'],
})
export class SoundButtonComponent {
  private readonly _soundOnUrl = '/assets/icons/sound-on.png';
  private readonly _soundOffUrl = '/assets/icons/sound-off.png';

  private _sound: SoundService = inject(SoundService);
  private _eventQueue = inject(GAEventQueueService);

  public toggleSound(): void {
    this._sound.isSoundOn = !this._sound.isSoundOn;
    this._eventQueue.dispatch(new GAEvent(GAEventType.SOUND_CLICK, this._sound.isSoundOn ? 'on' : 'off'));
  }

  public getIconUrl(): string {
    return this._sound.isSoundOn ? this._soundOnUrl : this._soundOffUrl;
  }

  public getButtonLabel(): string {
    return this._sound.isSoundOn ? 'Turn off the sound' : 'Turn on the sound';
  }
}
