import { Injectable } from '@angular/core';
import { StatisticsService } from '../statistics';
import { IObjectTypeFilter } from '../../models';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  forkJoin,
  map,
} from 'rxjs';
import { MilitaryService } from '../military';

@Injectable({
  providedIn: 'root',
})
export class ObjectTypeFilterService {
  private _subscription!: Subscription;
  private _objectTypeFiltersSubject = new BehaviorSubject<IObjectTypeFilter[]>(
    []
  );
  private _getAllChecked = (objectTypeFilters: IObjectTypeFilter[]) => {
    return objectTypeFilters.every(({ value }) => value);
  };
  private _getIsSomeChecked = (objectTypeFilters: IObjectTypeFilter[]) => {
    return objectTypeFilters.some(({ value }) => value);
  };

  public objectTypeFilters$: Observable<IObjectTypeFilter[]> =
    this._objectTypeFiltersSubject.asObservable();
  public allChecked$: Observable<boolean> = combineLatest([
    this.objectTypeFilters$,
  ]).pipe(
    map(([objectTypeFilters]) => {
      return this._getAllChecked(objectTypeFilters);
    })
  );

  constructor(
    private readonly _statisticsService: StatisticsService,
    private readonly _militaryService: MilitaryService
  ) {
    this._subscription = forkJoin([
      this._militaryService.getTypes(),
      this._statisticsService.getObjectsDamage(),
    ])
      .pipe(
        map(([types, objectsDamage]) => {
          const objectTypeFilters: IObjectTypeFilter[] = types.map((type) => {
            const previousValue = this.objectTypeFilters?.find(
              (filter) => filter._id === type._id
            );

            const { countOfDestroyedObjects, countOfObjects, percentages } =
              objectsDamage?.[type._id] || {
                countOfDestroyedObjects: 0,
                countOfObjects: 0,
                percentages: 0,
              };

            const i18nLabelMapping: Record<string, string> = {
              'Піхотна база': 'objectType.infantryBase',
              Авіабаза: 'objectType.airForceBase',
              Танки: 'objectType.tanks',
              БТР: 'objectType.apc',
              Артилерія: 'objectType.artillery',
              ППО: 'objectType.airDefence',
              'Ракетна база': 'objectType.rocketBase',
              Склад: 'objectType.warehouse',
              Завод: 'objectType.plant',
              Кремль: 'objectType.kremlin',
              'Резиденція путіна в сочі': 'objectType.putinsResidenceInSochi',
              'Палатка шойгу': 'objectType.shoigusTent',
              Генштаб: 'objectType.generalStaff',
            };

            const label =
              type.title in i18nLabelMapping
                ? i18nLabelMapping[type.title]
                : type.title;

            const objectTypeFilter: IObjectTypeFilter = {
              _id: type._id,
              label,
              iconSrc: type.healthyPicture,
              total: countOfObjects,
              destroyed: countOfDestroyedObjects,
              percentages: percentages,
              value: previousValue?.value ?? true,
            };

            return objectTypeFilter;
          });

          return objectTypeFilters;
        })
      )
      .subscribe({
        next: (objectTypeFilters) => {
          this.objectTypeFilters = objectTypeFilters;
        },
      });
  }

  // Make sure to unsubscribe when the service is no longer needed
  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  public change(checked: boolean, index: number): void {
    const target: IObjectTypeFilter = {
      ...this.objectTypeFilters[index],
      value: !checked,
    };
    const objectTypeFilters = [
      ...this.objectTypeFilters.slice(0, index),
      target,
      ...this.objectTypeFilters.slice(index + 1),
    ];

    this.objectTypeFilters = objectTypeFilters;
  }

  public changeAll(checked: boolean): void {
    this.objectTypeFilters = this.objectTypeFilters.map((objectTypeFilter) => ({
      ...objectTypeFilter,
      value: checked,
    }));
  }

  get objectTypeFilters() {
    return this._objectTypeFiltersSubject.value;
  }

  set objectTypeFilters(value: IObjectTypeFilter[]) {
    this._objectTypeFiltersSubject.next(value);
  }

  get allChecked() {
    return this._getAllChecked(this.objectTypeFilters);
  }

  get isSomeChecked() {
    return this._getIsSomeChecked(this.objectTypeFilters);
  }
}
