<div>
  <div class="goal">
    <h5 class="goal__title">{{ "goalPanel.title" | translate }}</h5>
    <p class="goal__description">
      {{ "goalPanel.description" | translate }}
    </p>

    <div>
      <div class="goal__item">
        <img src="/assets/kamikaze-drone.webp" alt="Kamikaze drone" />
        <h6 class="goal__item-title">
          Hello
          {{ "goalPanel.items.0.title" | translate }}
        </h6>
        <p class="goal__item-description">
          {{ "goalPanel.items.0.description" | translate }}
        </p>
      </div>
      <div class="goal__item">
        <img src="/assets/first-aid-kit.webp" alt="First aid kit" />
        <h6 class="goal__item-title">
          {{ "goalPanel.items.1.title" | translate }}
        </h6>
        <p class="goal__item-description">
          {{ "goalPanel.items.1.description" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
