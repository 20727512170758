import { Injectable } from '@angular/core';
import { GAEventType } from 'src/app/ga-events';
import { environment } from 'src/environment/environment';

declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private _mapScreenCategory = [
    GAEventType.PROJECT_AIM_CLICK,
    GAEventType.EASY_DONATE_CLICK,
    GAEventType.ABOUT_US_CLICK,
    GAEventType.PROGRESS_CLICK,
    GAEventType.SOON_CLICK,
    GAEventType.LANGUAGE_CLICK,
    GAEventType.FIELD_CLICK,
    GAEventType.CHAT_CLICK,
    GAEventType.SOUND_CLICK,
    GAEventType.MINI_MAP_CLICK,
    GAEventType.OBJECTS_CLICK
  ];

  private objectDetailsCategory = [
    GAEventType.SHARE,
    GAEventType.COORDINATE_CLICK,
    GAEventType.DESTROY_CLICK
  ]

  private _paymentCategory = [
    GAEventType.BEGIN_CHECKOUT,
    GAEventType.PURCHASE,
    GAEventType.ADD_TO_CART
  ]

  public initialize() {
    try {
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.src = `${url}${environment.googleAnalyticsId}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.googleAnalyticsId}', {'send_page_view': false});`;

      const metaTag = document.createElement('meta');
      metaTag.name = 'google-site-verification';
      metaTag.content = 'mOO1GFaTpS3nobrc4pcSGjSzWR075UozYV9AsNXtE5E';

      document.head.appendChild(metaTag);
      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }

  public event(
    action: GAEventType,
    value?: string
  ) {
    const eventCategory = this._mapScreenCategory.includes(action)
      ? 'Map screen'
      : this.objectDetailsCategory.includes(action)
      ? 'Object details'
      : this._paymentCategory.includes(action)
      ? 'Payment'
      : null;
    
    gtag('event', action, {
      ...(eventCategory && { event_category: eventCategory }),
      ...(value && { value: value }),
    });
  }
}
