import { Injectable, inject } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, map } from 'rxjs';
import { IDamage } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class RealtimeService {
  private _socket = inject(Socket);

  onFetchDamage(): Observable<IDamage> {
    return this._socket
      .fromEvent<string>('/public/damage')
      .pipe(map((data) => JSON.parse(data) as IDamage));
  }

  onFetchTransaction() {
    return this._socket.fromEvent('/public/chat');
  }

  onFetchRevenue() {
    return this._socket.fromEvent<string>('/public/revenue');
  }

  onFetchPhases() {
    return this._socket.fromEvent<string>('/public/phases-damage');
  }
}
