import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IHttpParams, IListResponse } from 'src/app/core/interfaces';
import { IWeapon } from '../../models/weapon.interface';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class WeaponService {
  private _http = inject(HttpClient);

  getWeapons(): Observable<IListResponse<IWeapon>> {
    const params = {
      page: 1,
      pageSize: 10,
    } satisfies IHttpParams;

    return this._http.get<IListResponse<IWeapon>>(
      `${environment.apiGateway}/v1/map/military/weapons`,
      { params }
    );
  }

  getSpecialWeapons(): Observable<IListResponse<IWeapon>> {
    const params = {
      page: 1,
      pageSize: 10,
    } satisfies IHttpParams;

    return this._http.get<IListResponse<IWeapon>>(
      `${environment.apiGateway}/v1/map/military/special-weapons`,
      { params }
    );
  }
}
