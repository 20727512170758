import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-widget',
  templateUrl: './progress-widget.component.html',
  styleUrls: ['./progress-widget.component.css'],
})
export class ProgressWidgetComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) value: number = 0;
  @Input() showPercentages: boolean = true;
  @Input() range: number[] = [0, 0];
  @Input() color: string | null = null;
}
