import { IMarkerData } from '../models';
import * as L from 'leaflet';
import { calculatePercentage } from './math.helper';
import { environment as env } from 'src/environment/environment';
import { ObjectState } from '../enums';
import { i18nObjectTypeTitleMapping } from './i18n.helper';

export const generateMapIcon = (data: IMarkerData): L.DivIcon => {
  let iconUrl: string;

  switch (data.state) {
    case 'locked':
      iconUrl = env.DOMEN + data.lockedPicture;
      break;
    case 'damaged':
      iconUrl = env.DOMEN + data.damagedPicture;
      break;
    case 'destroyed':
      iconUrl = env.DOMEN + data.destroyedPicture;
      break;
    default:
      iconUrl = env.DOMEN + data.healthyPicture;
      break;
  }

  return new L.DivIcon({
    iconSize: [64, 64],
    className: `facility-icon ${data.isVip ? 'boss' : ''} ${
      data.name === i18nObjectTypeTitleMapping['Кремль'] ? 'kremlin' : ''
    }`,
    html: `
        <div class="icon-wrapper" data-cof="${data.scaleLevel}" data-id="${
      data.id
    }">
          ${
            data.currentHp > 0
              ? `<div class="facility-health">
                    <div class="inner" style="width: ${calculatePercentage(
                      data.currentHp,
                      data.health
                    )}%;"></div>
                </div>`
              : ''
          }
            <img src="${iconUrl}" alt="${data.description}" title="${data.description}" width="64" height="64">
        </div>
        `,
  });
};

export const generateIcon = (data: IMarkerData): string | undefined => {
  switch (data.state) {
    case ObjectState.LOCKED:
      return env.DOMEN + data.lockedPicture;
    case ObjectState.DAMAGED:
      return env.DOMEN + data.damagedPicture;
    case ObjectState.DESTROYED:
      return env.DOMEN + data.destroyedPicture;
    case ObjectState.FULL:
    default:
      return env.DOMEN + data.healthyPicture;
  }
};
