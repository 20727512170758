import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-damage-type-switch',
  templateUrl: './damage-type-switch.component.html',
  styleUrls: ['./damage-type-switch.component.scss'],
  standalone: true,
})
export class DamageTypeSwitchComponent {
  @Input() checked: boolean = false;
  @Output() checkedChange = new EventEmitter<boolean>();

  onToggle() {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
