<div class="all-filter">
  <app-switch
    [(checked)]="allShown"
    (checkedChange)="change($event)"
  ></app-switch>
  <label>
    <h6>{{ "allFilter.title" | translate }}</h6>
    <p>{{ "allFilter.subTitle" | translate }}</p>
  </label>
</div>
