<div class="about-found">
  <div class="about-found__body">
    <p class="about-found__body-description">
      {{ "projectAbout.aboutFound.body.description.paragraphs.0" | translate }}
    </p>
    <p class="about-found__body-description">
      {{ "projectAbout.aboutFound.body.description.paragraphs.1" | translate }}
    </p>
    <p class="about-found__body-description">
      {{ "projectAbout.aboutFound.body.description.paragraphs.2" | translate }}
    </p>
    <p class="about-found__body-description">
      {{ "projectAbout.aboutFound.body.description.paragraphs.3" | translate }}
    </p>
    <div class="about-found__img">
      <img
        src="/assets/about-us-img.webp"
        width="966"
        height="264"
        alt="Smth"
      />
    </div>
    <p class="about-found__body-description">
      {{ "projectAbout.aboutFound.body.description.paragraphs.4" | translate }}
    </p>
  </div>

  <div class="legal-info">
    <h3 class="about-found__body-title">
      {{ "projectAbout.legalInfo.paragraphs.0" | translate }}
    </h3>
    <p class="about-found__body-description">
      {{ "projectAbout.legalInfo.paragraphs.1" | translate }}
    </p>
    <p class="about-found__body-description">
      {{ "projectAbout.legalInfo.paragraphs.2" | translate }}
    </p>
    <p class="about-found__body-description">
      {{ "projectAbout.legalInfo.paragraphs.3" | translate }}
    </p>
    <p class="about-found__body-description">
      {{ "projectAbout.legalInfo.paragraphs.4" | translate }}
    </p>
    <p class="about-found__body-description">
      {{ "projectAbout.legalInfo.paragraphs.5" | translate }}
    </p>
    <p class="about-found__body-description">
      {{ "projectAbout.legalInfo.paragraphs.6" | translate }}
    </p>
  </div>

  <div class="about-found__footer">
    <button nz-button nzType="primary" (click)="close()">
      {{ "projectAbout.backToMapButtonTitle" | translate }}
    </button>
  </div>
</div>
