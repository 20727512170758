import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressWidgetComponent } from './progress-widget.component';

import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCardModule } from 'ng-zorro-antd/card';

@NgModule({
  declarations: [ProgressWidgetComponent],
  imports: [CommonModule, NzProgressModule, NzDropDownModule, NzCardModule],
  exports: [ProgressWidgetComponent],
})
export class ProgressWidgetModule {}
