import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileSoonService {
  private _visibility = new Subject<boolean>();
  public visibility$ = this._visibility.asObservable();

  public changeVisibility(value: boolean): void {
    this._visibility.next(value);
  }
}
