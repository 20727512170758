<div class="damage-row flex-between">
  <div class="damage-row__main flex-align-center">
    <div
      class="damage-row__icon flex-center"
      [style]="{ background: '#E7EDEE' }"
    >
      <img src="/assets/avatars/molotov-cocktail-1.svg" alt="Avatar" />
    </div>
    <div class="damage-row__info">
      <h6 class="damage-row__title">
        {{ transaction.username || "Anonymous" }}
      </h6>
      <p class="damage-row__description">{{ transaction.message }}</p>
    </div>
  </div>

  <strong class="damage-row__value"
    >{{ transaction.amount }} {{ currencySymbol }}</strong
  >
</div>
