import { Injectable } from '@angular/core';
import { Observable, Subject, filter } from 'rxjs';
import { GAEvent } from './ga-events.class';
import { GAEventType } from './event.enum';

@Injectable({
  providedIn: 'root'
})
export class GAEventQueueService {
  private eventBrocker = new Subject<GAEvent<any>>();

  on(eventType: GAEventType): Observable<GAEvent<any>> {
    return this.eventBrocker.pipe(filter((event) => event.type === eventType));
  }

  listenAll(): Observable<GAEvent<any>> {
    return this.eventBrocker;
  }

  dispatch<T>(event: GAEvent<T>): void {
    this.eventBrocker.next(event);
  }
}
