import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environment/environment';
import { IPayOptions, IPaymentData } from '../../models';
import { LanguageService } from '../language';
import { liqPayLanguageMapping } from '../../helpers';

interface ISignedData {
  data: string;
  signature: string;
}

/**
 * Creates object with helpers for accessing to Liqpay API
 *
 * @param {string} publicKey
 * @param {string} privateKey
 * @throws {Error}
 */
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private _http: HttpClient,
    private _languageService: LanguageService
  ) {}

  _formObject(options: IPayOptions): Observable<ISignedData | null> {
    return this._http
      .post<ISignedData>(
        `${environment.apiGateway}/payments/form-object/shoot`,
        options,
        {
          responseType: 'json',
        }
      )
      .pipe(
        catchError((error) => {
          console.error('An error occurred during sign:', error);
          return of(null);
        })
      );
  }

  private _formCheckoutOptions({
    data,
    signature,
  }: {
    data: string;
    signature: string;
  }): ILiqPayCheckoutOptions {
    return {
      data,
      signature,
      embedTo: '#liqpay_checkout',
      language: liqPayLanguageMapping[this._languageService.language],
      mode: 'popup',
    };
  }

  private _getOptions = (
    options: IPayOptions
  ): Observable<ILiqPayCheckoutOptions | null> => {
    return this._formObject(options).pipe(
      map((res) => {
        if (!res) {
          throw new Error(
            `Expected to receive a form object, instead received "${typeof res}"`
          );
        }

        const { data, signature } = res;

        return this._formCheckoutOptions({ data, signature });
      }),
      catchError((error) => {
        console.error('Error occurred during forming options:', error);
        return of(null);
      })
    );
  };

  public pay = ({ options, callback, ready, close }: IPaymentData): void => {
    this._getOptions(options).subscribe((result) => {
      if (result) {
        window.LiqPayCheckout.init(result)
          .on('liqpay.callback', callback)
          .on('liqpay.ready', ready)
          .on('liqpay.close', close);
      }
    });
  };
}
