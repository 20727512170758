export enum GAEventType {
    // Map screen
    PROJECT_AIM_CLICK = 'project_aim_click',
    EASY_DONATE_CLICK = 'easy_donate_click',
    ABOUT_US_CLICK = 'about_us_click',
    PROGRESS_CLICK = 'progress_click',
    SOON_CLICK = 'soon_click',
    LANGUAGE_CLICK = 'languages_click',
    FILTER_CLICK = 'filter_click',
    CHAT_CLICK = 'chat_click',
    SOUND_CLICK = 'sound_click',
    MINI_MAP_CLICK = 'mini_map_click',
    OBJECTS_CLICK = 'objects_click',

    // Objects details 
    SHARE = 'share',
    COORDINATE_CLICK = 'coordinate_click',
    DESTROY_CLICK = 'destroy_click',

    // Payment 
    BEGIN_CHECKOUT = 'begin_checkout',
    PURCHASE = 'purchase',
    SELECT_ITEM = 'select_item',
    ADD_TO_CART = 'add_to_cart',
    FIELD_CLICK = 'field_click',
}