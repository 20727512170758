<div class="zoom-control">
  <button class="zoom-control__zoom-in" (click)="zoomIn()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M10 19C10.9941 19 11.8 18.1941 11.8 17.2V11.8H17.2C18.1941 11.8 19 10.9941 19 10C19 9.00589 18.1941 8.2 17.2 8.2H11.8V2.8C11.8 1.80589 10.9941 1 10 1C9.00589 1 8.2 1.80589 8.2 2.8V8.2H2.8C1.80589 8.2 1 9.00589 1 10C1 10.9941 1.80589 11.8 2.8 11.8H8.2V17.2C8.2 18.1941 9.00589 19 10 19Z"
        fill="#3F4254" />
    </svg>
  </button>
  <button class="zoom-control__zoom-out" (click)="zoomOut()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.8 11.6C1.80589 11.6 1 10.7941 1 9.8C1 8.80589 1.80589 8 2.8 8H17.2C18.1941 8 19 8.80589 19 9.8C19 10.7941 18.1941 11.6 17.2 11.6H2.8Z"
        fill="#3F4254" />
    </svg>
  </button>
</div>