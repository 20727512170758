<div class="reporting">
  <div class="reporting__body">
    <p class="reporting__body-description">
      {{ "projectAbout.reporting.body.description.paragraphs.0" | translate }}
    </p>
    <p class="reporting__body-description">
      {{ "projectAbout.reporting.body.description.paragraphs.1" | translate }}
      <a 
        href="https://docs.google.com/spreadsheets/d/1K8FiXlQe3Xs9oLp2L9N62dyo7JuOXRANOPkl0M4Ojp8/edit?gid=0#gid=0"
        target="_blank"
        rel="noopener noreferrer" 
      >report</a>
    </p>
    <p class="reporting__body-description">
      {{ "projectAbout.reporting.body.description.paragraphs.2" | translate }}
      <a 
        href="https://drive.google.com/drive/u/0/folders/1DzWDj2yIgcpQJcgxaaMbcqp4Oe-Hw8if"
        target="_blank"
        rel="noopener noreferrer"
      >photo-report</a>
    </p>
    <div class="reporting__image-container">
      <!-- <img src="/assets/reports/report-1_26-04-2024.webp" alt="Report 1" />
      <img src="/assets/reports/report-2_26-04-2024.webp" alt="Report 2" />
      <img src="/assets/reports/report-3_26-04-2024.webp" alt="Report 3" />
      <img src="/assets/reports/report-4_26-04-2024.webp" alt="Report 4" />
      <img src="/assets/reports/report-5_26-04-2024.webp" alt="Report 5" /> -->
    </div>
  </div>
  <div class="reporting__footer">
    <button nz-button nzType="primary" (click)="close()">
      {{ "projectAbout.backToMapButtonTitle" | translate }}
    </button>
  </div>
</div>
