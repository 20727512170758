import { BehaviorSubject, Observable } from 'rxjs';
import { IHttpParams } from 'src/app/core/interfaces';

const DEFAULT_HTTP_PARAMS: IHttpParams = {
  page: 1,
  pageSize: 50,
};

export interface IParamsHandler<P extends IHttpParams = IHttpParams> {
  readonly params$: Observable<P>;
  readonly params: P;
  readonly isFirstPage: boolean;
  readonly isLastPage: boolean;

  total: number;

  toNextPage(): void;
  toPreviousPage(): void;
  toFirstPage(): void;
  toLastPage(): void;
  toPage(pageIndex: number): void;
  changePageSize(limit: number): void;
  patchParams(params: Partial<P>): void;
  isParamsChanged(params: Partial<P>): boolean;
}

export class ParamsHandler<P extends IHttpParams = IHttpParams>
  implements IParamsHandler<P>
{
  _params$: BehaviorSubject<P>;
  _total = 0;
  params$: Observable<P>;

  get params(): any {
    return this._params$.value;
  }

  get isFirstPage(): boolean {
    return this.params?.page === 1;
  }

  get isLastPage(): boolean {
    return this.params?.page_size === 0
      ? true
      : this.params!.page * this.params!.page_size >= this.total;
  }

  set total(value: number) {
    this._total = value;
    if (this.params!.page > Math.ceil(this._total / this.params!.page_size)) {
      this.toLastPage();
    }
  }

  get total(): number {
    return this._total;
  }

  constructor(params: P = <P>DEFAULT_HTTP_PARAMS) {
    this._params$ = new BehaviorSubject<P>(params);
    this.params$ = this._params$.asObservable();
  }

  toNextPage(): void {
    if (!this.isFirstPage) {
      this.toPage(this.params!.page + 1);
    }
  }

  toPreviousPage(): void {
    if (!this.isFirstPage) {
      this.toPage(this.params!.page - 1);
    }
  }

  toFirstPage(): void {
    this.toPage(0);
  }

  toLastPage(): void {
    const pagesCount = Math.ceil(this.total / this.params!.page_size);
    this.toPage(pagesCount);
  }

  toPage(pageIndex: number): void {
    this.patchParams(<Partial<P>>{ page: pageIndex });
  }

  changePageSize(limit: number): void {
    this.patchParams(<Partial<P>>{ page: 1, pageSize: limit });
  }

  patchParams(params: Partial<P>): void {
    if (this.params && this.isParamsChanged(params)) {
      this._params$.next({ ...this.params, ...params });
    }
  }

  isParamsChanged(params: Partial<P>): boolean {
    return (Object.keys(params) as (keyof typeof params)[]).some(
      (paramsName) => this.params?.[paramsName] != params[paramsName]
    );
  }
}

export function shakeParams(params: any): any {
  Object.keys(params).forEach((p) => {
    if (params[p] === null || params[p] === undefined) {
      delete params[p];
    }

    if (Array.isArray(p) && !p.length) {
      delete params[p];
    }
  });

  return params;
}
