import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import * as L from 'leaflet';
import { MapService } from '../../services';

@Component({
  selector: 'app-zoom-control',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './zoom-control.component.html',
  styleUrls: ['./zoom-control.component.scss'],
})
export class ZoomControlComponent {
  constructor(private _mapService: MapService) {
    this._mapService.map$.subscribe({
      next: (map): void => {
        map?.addControl(L.control.zoom({ position: 'bottomleft' }));
      },
    });
  }

  public zoomIn(): void {
    this._mapService.map?.zoomIn();
  }
  public zoomOut(): void {
    this._mapService.map?.zoomOut();
  }
}
