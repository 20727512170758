export enum Language {
  ENGLISH = 'en',
  POLISH = 'pl',
  UKRAINIAN = 'ua',
}

export enum LiqPayLanguage {
  ENGLISH = 'en',
  UKRAINIAN = 'uk',
}
