import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService, LanguageService } from './shared/services';
import { environment } from 'src/environment/environment';
import { EEnvironment } from 'src/environment/environment.enum';
import {
  NzI18nService,
  en_US,
  uk_UA,
  pl_PL,
  NzI18nInterface,
} from 'ng-zorro-antd/i18n';
import { Language } from './shared/enums';
import { GAEventQueueService, GAEventType } from './ga-events';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(
    private _languageService: LanguageService,
    private _translateService: TranslateService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private _nzi18nService: NzI18nService,
    private _eventQueue: GAEventQueueService,
  ) {
    this._translateService.setDefaultLang(this._languageService.language);
    this._languageService.language$.subscribe((language) => {
      this._translateService.use(language);

      const nzi18nLocaleMapping: Record<Language, NzI18nInterface> = {
        [Language.ENGLISH]: en_US,
        [Language.UKRAINIAN]: uk_UA,
        [Language.POLISH]: pl_PL,
      };
      this._nzi18nService.setLocale(nzi18nLocaleMapping[language]);
    });
  }

  public ngOnInit(): void {
    if (environment.name === EEnvironment.PRODUCTION) {
      this.googleAnalyticsService.initialize();
    }

    this._eventQueue.listenAll().pipe(untilDestroyed(this)).subscribe((event) => {
      if (environment.name !== EEnvironment.PRODUCTION) {
        return;
      }
      this.googleAnalyticsService.event(event.type, event.payload);
    });
  }
}
