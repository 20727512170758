<div class="project-about__pin" role="button" (click)="open()">
  <img
    src="/assets/icons/about-bg.svg"
    width="112"
    height="151"
    class="project-about__bg"
    alt="Bg"
  />

  <img
    src="/assets/icons/arrow-down.svg"
    class="project-about__pin-arrow"
    width="18"
    height="18"
    alt="Expand"
  />

  <img
    src="/assets/logo.png"
    class="project-about__pin-logo"
    width="106"
    height="102"
    alt="Logo"
  />
</div>
<nz-drawer
  [nzClosable]="false"
  [nzVisible]="isDrawerVisible"
  nzPlacement="top"
  [nzHeight]="'100dvh'"
  [nzTitle]="undefined"
  nzWrapClassName="about-drawer"
  (nzOnClose)="close()"
  [nzMaskClosable]="true"
>
  <ng-container *nzDrawerContent>
    <button class="close-btn" aria-label="Close modal window" (click)="close()">
      <img
        src="/assets/icons/close-icon.svg"
        width="18"
        height="18"
        alt="Close"
      />
    </button>
    <div class="container">
      <nz-tabset nzCentered>
        <nz-tab nzTitle="{{ 'projectAbout.aboutFound.title' | translate }}">
          <app-about-found (onClose)="close()"></app-about-found>
        </nz-tab>
        <nz-tab nzTitle="{{ 'projectAbout.aboutProject.title' | translate }}">
          <app-about-project (onClose)="close()"></app-about-project>
        </nz-tab>
        <nz-tab nzTitle="{{ 'projectAbout.reporting.title' | translate }}">
          <app-reporting (onClose)="close()"></app-reporting>
        </nz-tab>
        <nz-tab nzTitle="{{ 'projectAbout.qa.title' | translate }}">
          <app-qa (onClose)="close()"></app-qa>
        </nz-tab>
      </nz-tabset>
    </div>
  </ng-container>
</nz-drawer>
