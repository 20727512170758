import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import {
  ILoadingHandler,
  LoadingHandlerToken,
  provideLoadingHandler,
} from '../../classes';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { ScreenResolutionService } from '../../services/screen-resolution/screen-resolution.service';
import { DamageTypeFiltersComponent } from './components/damage-type-filters/damage-type-filters.component';
import { ObjectTypeFiltersComponent } from './components/object-type-filters/object-type-filters.component';
import { AllFilterComponent } from './components/all-filter/all-filter.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { MarkerFilterService } from '../../services';
import { TranslateModule } from '@ngx-translate/core';
import { GAEvent, GAEventQueueService, GAEventType } from 'src/app/ga-events';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    NzPopoverModule,
    NzSwitchModule,
    NzDrawerModule,
    AllFilterComponent,
    DamageTypeFiltersComponent,
    ObjectTypeFiltersComponent,
    TranslateModule,
  ],
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers: [provideLoadingHandler()],
})
export class FilterComponent {
  public isMobile!: boolean;
  public isVisible: boolean = false;
  public placement: string = 'rightBottom';
  public allChecked: boolean;

  private _eventQueue = inject(GAEventQueueService);

  public change(value: boolean): void {
    this.isVisible = value && !this.isMobile;

    if (value) {
      this._eventQueue.dispatch(new GAEvent(GAEventType.FILTER_CLICK, value));
    }
  }

  public close(): void {
    this.isVisible = false;
  }

  public open(): void {
    this.isVisible = true;
    this._eventQueue.dispatch(new GAEvent(GAEventType.FILTER_CLICK, true));
  }

  constructor(
    private _screenResolutionService: ScreenResolutionService,
    private _markerFilterService: MarkerFilterService,
    @Inject(LoadingHandlerToken) public loadingHandler: ILoadingHandler
  ) {
    this._screenResolutionService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
      this.placement = isMobile ? 'leftBottom' : 'rightBottom';
    });

    this.allChecked = this._markerFilterService.allChecked;

    this._markerFilterService.allChecked$.subscribe({
      next: (allChecked) => {
        this.allChecked = allChecked;
      },
    });
  }
}
