import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from '../../enums';
import { isLanguageString, rfc5646LanguageMapping } from '../../helpers';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly LANG_STORAGE_KEY = 'language';
  private _languageSubject: BehaviorSubject<Language>;

  public language$: Observable<Language>;

  constructor() {
    // Load the language from local storage
    const storedValue = localStorage.getItem(this.LANG_STORAGE_KEY);
    const parsedValue = storedValue
      ? JSON.parse(storedValue)
      : this._defaultLanguage;
    const language: Language =
      typeof parsedValue === 'string' && isLanguageString(parsedValue)
        ? parsedValue
        : this._defaultLanguage;

    this._languageSubject = new BehaviorSubject(language);
    this.language$ = this._languageSubject.asObservable();
  }

  get language(): Language {
    return this._languageSubject.value;
  }

  public setLanguage(language: Language): void {
    this._languageSubject.next(language);
    localStorage.setItem(this.LANG_STORAGE_KEY, JSON.stringify(language));
  }

  private get _defaultLanguage(): Language {
    if (rfc5646LanguageMapping[Language.ENGLISH].includes(navigator.language)) {
      console.log(Language.ENGLISH);
      return Language.ENGLISH;
    }

    if (rfc5646LanguageMapping[Language.POLISH].includes(navigator.language)) {
      console.log(Language.POLISH);
      return Language.POLISH;
    }

    console.log(Language.UKRAINIAN);
    return Language.UKRAINIAN;
  }
}
