import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSpace',
  standalone: true,
})
export class NumberSpacePipe implements PipeTransform {
  transform(value: number): string {
    const stringValue = String(value);
    let result = '';
    let count = 0;

    for (let i = stringValue.length - 1; i >= 0; i--) {
      result = stringValue[i] + result;
      count++;
      if (count % 3 === 0 && i > 0) {
        result = ' ' + result;
      }
    }

    return result;
  }
}
