import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NzListModule } from 'ng-zorro-antd/list';
import { DamageTypeSwitchComponent } from './components/damage-type-switch/damage-type-switch.component';
import { IDamageTypeFilter } from 'src/app/shared/models';
import { MarkerFilterService } from 'src/app/shared/services';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-damage-type-filters',
  templateUrl: './damage-type-filters.component.html',
  styleUrls: ['./damage-type-filters.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NzListModule,
    DamageTypeSwitchComponent,
    TranslateModule,
  ],
})
export class DamageTypeFiltersComponent {
  public damageTypeFilters: IDamageTypeFilter[] = [];

  constructor(private readonly _markerFilterService: MarkerFilterService) {
    this._markerFilterService.damageTypeFilters$.subscribe(
      (damageTypeFilters) => {
        this.damageTypeFilters = damageTypeFilters;
      }
    );
  }

  public change(value: boolean, index: number): void {
    this._markerFilterService.changeDamageTypeFilter(value, index);
  }
}
