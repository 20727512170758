import { EEnvironment } from './environment.enum';
import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  name: EEnvironment.DEVELOPMENT,
  apiGateway: 'https://dev-api.disarmru.org',
  socketGateway: 'https://dev-socket.disarmru.org',
  DOMEN: 'https://web-dev.disarmru.org',
  mapTilerApiKey: 'nlqsn0NllN6Ew3mNYrKC',
  googleAnalyticsId: undefined,
  damagedAfter: 50
};
