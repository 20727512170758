import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private _mapSubject: BehaviorSubject<L.Map | undefined> = new BehaviorSubject<
    L.Map | undefined
  >(undefined);
  public map$: Observable<L.Map | undefined> = this._mapSubject.asObservable();

  get map() {
    return this._mapSubject.getValue();
  }

  set map(value: L.Map | undefined) {
    this._mapSubject.next(value);
  }
}
