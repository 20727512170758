/* eslint-disable no-prototype-builtins */
import { Directive, OnInit, inject } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export interface IErrorHandler {
  getError(key: string, errors: any): string;
}

export class TranslateErrorHandler implements IErrorHandler {
  constructor(private _suffix: string) {}

  getError(key: string, errors: any): string {
    const errorKey = Object.keys(errors)[0];

    return [this._suffix, key, errorKey].filter((str) => str).join('.');
  }
}

@Directive()
export abstract class FormComponent<Controls = any> implements OnInit {
  protected _translate: TranslateService = inject(TranslateService);

  public form!: FormGroup;
  public errorHandler: IErrorHandler = new TranslateErrorHandler('errors');
  public errors: { [P in keyof Controls]?: string } = {};

  protected abstract createForm(): FormGroup;

  get controls(): { [key in keyof Partial<Controls>]: AbstractControl } {
    return (
      this.form &&
      (this.form.controls as {
        [key in keyof Partial<Controls>]: AbstractControl;
      })
    );
  }

  public ngOnInit(): void {
    this.form = this.createForm();
  }

  public apply(): void {
    this._validateForm();
    if (this.form.invalid) {
      this._handleFormInvalid();
    }
  }

  protected _handleFormInvalid(): void {
    this._showErrorMessage();
  }

  protected _validateForm() {
    for (const key in this.controls)
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key];
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }

    this.form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.form.markAllAsTouched();
    this._setErrors();
  }

  protected _setErrors() {
    const errors: { [key in keyof Controls]?: string } = {};

    // eslint-disable-next-line no-prototype-builtins
    for (const key in this.controls)
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key];
        errors[key] =
          (control.dirty || control.touched) && control.invalid
            ? this.errorHandler.getError(key, control.errors)
            : '';
      }

    this.errors = errors;
  }

  protected _showErrorMessage(): void {
    const firstError = (this.errors as any)[
      Object.keys(this.errors).filter((key) => !!(this.errors as any)[key])[0]
    ];
  }

  protected _createFormData(): FormData {
    const formData = new FormData();

    for (const key in this.form.value)
      if (this.form.value.hasOwnProperty(key)) {
        const value = this.form.value[key];
        formData.append(key, value === null ? '' : value);
      }

    return formData;
  }
}
