import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { LanguageService, ScreenResolutionService } from '../../services';
import * as i18n from 'src/assets/i18n';
import { Language } from '../../enums';
import { GAEvent, GAEventQueueService, GAEventType } from 'src/app/ga-events';

interface ILanguageDetails {
  icon: string;
  label: string;
  value: Language;
}

@Component({
  selector: 'app-language-selector',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    NzPopoverModule,
    NzSwitchModule,
    NzDrawerModule,
    TranslateModule,
  ],
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
})
export class LanguageSelectorComponent {
  public isMobile!: boolean;
  public isVisible: boolean = false;
  public placement: string = 'rightBottom';

  private _eventQueue = inject(GAEventQueueService);

  public change(value: boolean): void {
    this.isVisible = value && !this.isMobile;
  }

  public close(): void {
    this.isVisible = false;
  }

  public open(): void {
    this.isVisible = true;
  }

  constructor(
    private _screenResolutionService: ScreenResolutionService,
    private _languageService: LanguageService,
    private _translateService: TranslateService
  ) {
    this._screenResolutionService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
      this.placement = isMobile ? 'leftBottom' : 'rightBottom';
    });

    this._languageService.language$.subscribe((language) => {
      this.selectedLanguage = this.languageMapping[language];
    });
  }

  private readonly _english: ILanguageDetails = {
    icon: '/assets/icons/flags/us.png',
    label: i18n.en.languageSelectorLabel.english,
    value: Language.ENGLISH,
  };

  private readonly _polish: ILanguageDetails = {
    icon: '/assets/icons/flags/pl.png',
    label: i18n.pl.languageSelectorLabel.polish,
    value: Language.POLISH,
  };

  private readonly _ukrainian: ILanguageDetails = {
    icon: '/assets/icons/flags/ua.png',
    label: i18n.ua.languageSelectorLabel.ukrainian,
    value: Language.UKRAINIAN,
  };

  public languageMapping: Record<Language, ILanguageDetails> = {
    [Language.UKRAINIAN]: this._ukrainian,
    [Language.ENGLISH]: this._english,
    [Language.POLISH]: this._polish,
  };

  public listOfLanguages: ILanguageDetails[] = Object.values(
    this.languageMapping
  );

  public selectedLanguage: ILanguageDetails = this._ukrainian;

  public selectLanguage(language: Language): void {
    this._languageService.setLanguage(language);
    this._eventQueue.dispatch(new GAEvent(GAEventType.LANGUAGE_CLICK, language));
  }
}
