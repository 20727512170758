<div class="facility-widget">
    <div class="facility-widget__img">
        <img [src]="generateIcon(facility)" width="200" height="160" *ngIf="facility" [alt]="facility.description">
    </div>

    <div class="facility-widget__subtitle">
        <span >{{damaged | numberSpace}}</span> / <strong>{{facility.health | numberSpace}}</strong>
    </div>

    <div class="facility-widget__progress" >
        <nz-progress class="facility-progress" [nzPercent]="persentage" [nzShowInfo]="false" [nzStrokeWidth]="24" [nzStrokeColor]="'#D32020'"
            [nzStrokeLinecap]="'square'"></nz-progress>
        
        <div *ngIf="damage" [style]="{width: persentagePreview + '%'}" class="damage-preview"> </div>

        <p>
            <strong>{{persentage | number:'1.0-0'}}% </strong>
        </p>
    </div>
</div>