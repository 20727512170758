<ng-container *ngIf="isTablet">
    <nz-drawer
        [(nzVisible)]="isVisible"
        [nzContent]="profile"
        [nzClosable]="false"
        nzWrapClassName="language-selector-drawer"
        nzHeight="auto"
        (nzOnClose)="close()"
        [nzTitle]="undefined"
        nzPlacement="bottom"
    ></nz-drawer>
    <ng-template #profile>
        <div class="soon">
            <div class="soon__head">
                <img src="/assets/icons/clock.svg" class="" width="34" height="34" alt="Soon" />
                <h5>{{ "projectProfile.soon" | translate }}</h5>
                <p [innerHTML]="'projectProfile.oneLineSoonSecondary' | translate"></p>
            </div>
            <div class="soon__body">
                <div class="soon__body__info-item">
                    <div class="soon__body__info-item-icon">
                      <img
                        src="/assets/avatars/molotov-cocktail-2.svg"
                        width="32"
                        height="32"
                        alt="{{ 'projectProfile.registration' | translate }}"
                      />
                    </div>
                    <h6 class="soon__body__info-item-title">
                      {{ "projectProfile.registration" | translate }}
                    </h6>
                  </div>
                  <div class="soon__body__info-item">
                    <div class="soon__body__info-item-icon">
                      <img
                        src="/assets/icons/cup-reward.svg"
                        width="32"
                        height="32"
                        alt="{{ 'projectProfile.awards' | translate }}"
                      />
                    </div>
                    <h6 class="soon__body__info-item-title">
                      {{ "projectProfile.awards" | translate }}
                    </h6>
                  </div>
            </div>

            <div class="soon__footer" (click)="close()">
                <img src="/assets/icons/arrow-down.svg" width="18" height="18" alt="Arrow">
            </div>
        </div>
    </ng-template>
</ng-container>


