import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoalPanelComponent } from './goal-panel.component';
import { ProgressWidgetModule } from '../progress-widget';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [GoalPanelComponent],
  imports: [
    CommonModule,
    ProgressWidgetModule,
    TranslateModule,
    TranslateModule,
  ],
  exports: [GoalPanelComponent],
})
export class GoalPanelModule {}
