<header [ngClass]="{overlayed: isGoalExpanded}">
  <div class="header-wrapper" *ngIf="!tabletMode; else tablet">
    <div class="header-wrapper__project-goal">
      <app-project-goal (expanded)="onGoalExpanded($event)"></app-project-goal>
    </div>
    <div class="header-wrapper__about">
      <app-project-about></app-project-about>
    </div>
    <div class="grid-wrap">
      <div class="header-wrapper__project-stages">
        <app-project-stages></app-project-stages>
      </div>
      <div class="header-wrapper__project-profile">
        <app-project-profile></app-project-profile>
      </div>
    </div>
  </div>
  <ng-template #tablet>
    <div class="header-mobile-wrapper">
      <div class="header-mobile-logo">
        <app-project-about></app-project-about>
      </div>
      <div class="header-mobile-info">
        <app-project-stages></app-project-stages>
        <app-project-profile></app-project-profile>
      </div>
    </div>
    <app-project-goal (expanded)="onGoalExpanded($event)"></app-project-goal>
  </ng-template>
</header>
