import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { ScreenResolutionService } from 'src/app/shared/services';
import { ProfileSoonService } from './profile-soon.service';

@UntilDestroy()
@Component({
  selector: 'app-profile-soon-drawer',
  standalone: true,
  imports: [
    CommonModule,
    NzDrawerModule,
    TranslateModule
  ],
  templateUrl: './profile-soon-drawer.component.html',
  styleUrl: './profile-soon-drawer.component.scss'
})
export class ProfileSoonDrawerComponent implements OnInit {
  public isTablet!: boolean;
  public isVisible: boolean = false;

  public change(value: boolean): void {
    this.isVisible = value && this.isTablet;
  }

  public close(): void {
    this.isVisible = false;
  }

  public open(): void {
    this.isVisible = true;
  }

  constructor(
    private _screenResolutionService: ScreenResolutionService,
    private _profileSoonService: ProfileSoonService
  ) {
    this._screenResolutionService.isTablet$.subscribe((isTablet) => {
      this.isTablet = isTablet;
    });
  }

  public ngOnInit(): void {
    this._profileSoonService.visibility$
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.change(value);
      });
  }
}
