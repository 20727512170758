export const i18nObjectTypeTitleMapping: Record<string, string> = {
  'Піхотна база': 'objectType.infantryBase',
  Авіабаза: 'objectType.airForceBase',
  Танки: 'objectType.tanks',
  БТР: 'objectType.apc',
  Артилерія: 'objectType.artillery',
  ППО: 'objectType.airDefence',
  'Ракетна база': 'objectType.rocketBase',
  Склад: 'objectType.warehouse',
  Завод: 'objectType.plant',
  Кремль: 'objectType.kremlin',
  'Резиденція путіна в сочі': 'objectType.putinsResidenceInSochi',
  'Палатка шойгу': 'objectType.shoigusTent',
  Генштаб: 'objectType.generalStaff',
};
