import { Currency } from '../enums';

/**
 * Retrieves the currency symbol for the given currency.
 * If the currency symbol is not found, returns the currency code itself.
 * @param currency The currency for which to retrieve the symbol.
 * @returns The currency symbol as a string, or the currency code if symbol not found.
 */
export const getCurrencySymbol = (currency: Currency): string => {
  switch (currency) {
    case Currency.EUR:
      return '€';
    case Currency.USD:
      return '$';
    case Currency.UAH:
      return '₴';
    default:
      return currency;
  }
};
