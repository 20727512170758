<div class="progress-widget"  nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
    <div class="progress-widget__content">
        <div class="progress-widget__header">
            <h6>{{title}}:</h6>
            <span>
                <ng-container *ngIf="showPercentages; else outOfValues">
                    <span [style]="{color: color}">{{value}} %</span>
                </ng-container>
                <ng-template #outOfValues>
                    <span [style]="{color: color}">{{range[0] | number: '1.0-0' }}</span> / {{range[1] | number: '1.0-0' }}
                </ng-template>
            </span>
        </div>

        <nz-progress 
            [nzPercent]="value" 
            [nzShowInfo]="false"
            [nzStrokeWidth]="6"
            [nzStrokeColor]="color!"
        ></nz-progress>
    </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
    <nz-card style="width:100%; max-width: 307px;" class="widget-dropdown">
        <ng-content></ng-content>
    </nz-card>
</nz-dropdown-menu>