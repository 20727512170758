import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GAEvent, GAEventQueueService, GAEventType } from 'src/app/ga-events';
import { ProfileSoonService } from 'src/app/modals/profile-soon-drawer';

@Component({
  selector: 'app-project-profile',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './project-profile.component.html',
  styleUrls: ['./project-profile.component.scss'],
})
export class ProjectProfileComponent {
  private _profileSoonService = inject(ProfileSoonService);
  private _eventQueue = inject(GAEventQueueService);

  public open(): void {
    this._profileSoonService.changeVisibility(true);
    this._eventQueue.dispatch(new GAEvent(GAEventType.SOON_CLICK, true));
  }
}
