import * as L from 'leaflet';
import 'leaflet.marker.slideto';

import { IMarkerData } from '../models';
import { generateMapIcon, i18nObjectTypeTitleMapping } from '../helpers';
import { environment } from 'src/environment/environment';

export class DataMarker extends L.Marker {
  private _markerData: IMarkerData;
  private _damagedAfter = environment.damagedAfter;

  private defaultIcon: L.DivIcon;
  private boomIcon = L.icon({
    iconUrl: '/assets/gif/explosion_time_new_animate.gif',
    iconSize: [108, 108],
    iconAnchor: [44, 73],
    className: 'boom-icon',
  });
  
  private specialBoomIcon = L.icon({
    iconUrl: '/assets/gif/egg-explosion.gif',
    iconSize: [108, 108],
    iconAnchor: [44, 73],
    className: 'boom-icon',
  });

  private defaultWeaponTypes = ['Дрон', 'Байрактар', 'Гаубиця 777', 'Himars', 'F16'];

  get data(): IMarkerData {
    return this._markerData;
  }

  set data(value: IMarkerData) {
    this._markerData = value;
  }

  private _generateIcon = generateMapIcon;

  constructor(
    public latLng: L.LatLngLiteral,
    data: IMarkerData,
    options?: L.MarkerOptions
  ) {
    super(latLng, options);

    const bossScaleLevel =
      data.name === i18nObjectTypeTitleMapping['Кремль'] ? 2.5 : 1.75;

    this._markerData = {
      ...data,
      scaleLevel: data.isVip ? bossScaleLevel : 1,
    };

    this.defaultIcon = this._generateIcon(this.data);
    this.setIcon(this.defaultIcon);
  }

  boomLocation(damage?: number, weaponType?: string): void {
    if (weaponType && !this.defaultWeaponTypes.includes(weaponType)) {
      this.setIcon(this.specialBoomIcon);
    } else {
      this.setIcon(this.boomIcon);
    }

    setTimeout(() => {
      this.setIcon(this._generateIcon(this.data));
    }, 1200);

    if (!damage) {
      return;
    }

    const persantage =
      ((this.data.currentHp - damage) * 100) / this.data.health;

    this.data.currentHp -= damage;
    if (this.data.currentHp < 0) {
      this.data.currentHp = 0;
    }

    if (persantage <= this._damagedAfter && persantage > 0) {
      this.data.state = 'damaged';
    } else if (persantage <= 0) {
      this.data.state = 'destroyed';
    }
  }

  cancel(): void {
    this.setIcon(this.defaultIcon);
  }
}
