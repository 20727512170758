import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  inject,
  type OnInit,
} from '@angular/core';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzButtonModule } from 'ng-zorro-antd/button';
import {
  RealtimeService,
  StatisticsService,
  TransactionService,
} from 'src/app/shared/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IPhasesDamageList } from 'src/app/shared/models';
import { ScreenResolutionService } from 'src/app/shared/services/screen-resolution/screen-resolution.service';
import { TranslateModule } from '@ngx-translate/core';
import { GAEvent, GAEventQueueService, GAEventType } from 'src/app/ga-events';

@UntilDestroy()
@Component({
  selector: 'app-project-stages',
  standalone: true,
  imports: [
    CommonModule,
    NzProgressModule,
    NzCollapseModule,
    NzDrawerModule,
    NzButtonModule,
    TranslateModule,
  ],
  templateUrl: './project-stages.component.html',
  styleUrls: ['./project-stages.component.scss'],
})
export class ProjectStagesComponent implements OnInit {
  public progressCircleWidth = 50;
  public progressStrokeWidth = 8;
  public tabletMode = false;
  public isDrawerVisible = false;
  public phases: IPhasesDamageList | undefined;

  public isActive = false;

  private elementRef = inject(ElementRef);
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.isActive) this.isActive = false;
    }
  }

  private _statisticsService = inject(StatisticsService);
  private _transactionService = inject(TransactionService);
  private _realtimeService = inject(RealtimeService);
  private _eventQueue = inject(GAEventQueueService);

  constructor(private _screenResolutionService: ScreenResolutionService) {
    this._screenResolutionService.isTablet$.subscribe((isTablet) => {
      this.tabletMode = isTablet;
    });
  }

  ngOnInit(): void {
    this._statisticsService
      .getPhasesDamage()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.phases = data;
      });

    this._realtimeService
      .onFetchPhases()
      .pipe(untilDestroyed(this))
      .subscribe((response: string) => {
        this.phases = JSON.parse(response);
      });
  }

  public close(): void {
    this.isDrawerVisible = false;
  }

  public open(): void {
    this.isDrawerVisible = true;
    this._eventQueue.dispatch(new GAEvent(GAEventType.PROGRESS_CLICK, true));
  }

  public stateChange(state: boolean): void {
    if (state) {
      this._eventQueue.dispatch(new GAEvent(GAEventType.PROGRESS_CLICK, state));
    }
  }

  public donate(): void {
    this.close();
    this._transactionService.noEffortDonate();
  }
}
