import CryptoJS from 'crypto-js';

export const calculatePercentage = (x: number, y: number): number => {
  return (x / y) * 100;
};

/**
 * Checks if a value is a finite number.
 *
 * @param {unknown} value - The value to check for finiteness.
 * @returns {boolean} `true` if the value is a finite number, otherwise `false`.
 */
export const isFiniteNumber = (value: unknown): value is number => {
  return typeof value === 'number' && Number.isFinite(value);
};

/**
 * Returns a random item from the given array.
 * @param {T[]} array - The array from which to select a random item.
 * @returns {T} - A random item from the array.
 * @throws {Error} - Throws an error if the input is not a non-empty array.
 */
export const getRandomItem = <T>(array: T[]): T => {
  if (!Array.isArray(array) || array.length === 0) {
    throw new Error('Input must be a non-empty array');
  }

  const randomNumber = CryptoJS.lib.WordArray.random(4).toString(
    CryptoJS.enc.Hex
  );
  const parsedRandomNumber = parseInt(randomNumber, 16);
  const scaledRandomNumber = parsedRandomNumber % (array.length + 1);

  const randomIndex = Math.floor(scaledRandomNumber);
  return array[randomIndex];
};
