<div class="about-project">
  <div class="about-project__head">
    <div class="about-project__head-title">
      {{ "projectAbout.aboutProject.body.title" | translate }}
    </div>
    <div class="about-project__head-subtitle">
      {{ "projectAbout.aboutProject.body.subTitle" | translate }}
    </div>
  </div>
  <div class="about-project__body">
    <p class="about-project__body-description">
      {{
        "projectAbout.aboutProject.body.description.paragraphs.0" | translate
      }}
    </p>
    <h3 class="about-project__body-header">
      {{ "projectAbout.aboutProject.body.description.goal" | translate }}
    </h3>
    <p class="about-project__body-description">
      {{
        "projectAbout.aboutProject.body.description.paragraphs.1" | translate
      }}
    </p>
    <h3 class="about-project__body-header">
      {{
        "projectAbout.aboutProject.body.description.challengesAndIdea"
          | translate
      }}
    </h3>
    <p class="about-project__body-description">
      {{
        "projectAbout.aboutProject.body.description.paragraphs.2" | translate
      }}
    </p>
    <p class="about-project__body-description">
      {{
        "projectAbout.aboutProject.body.description.paragraphs.3" | translate
      }}
    </p>
  </div>
  <div class="about-project__footer">
    <button nz-button nzType="primary" (click)="close()">
      {{ "projectAbout.backToMapButtonTitle" | translate }}
    </button>
  </div>
</div>
