import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenResolutionService {
  private _isMobileSubject = new BehaviorSubject<boolean>(false);
  private _isTabletSubject = new BehaviorSubject<boolean>(false);

  public isMobile$ = this._isMobileSubject.asObservable();
  public isTablet$ = this._isTabletSubject.asObservable();

  constructor(private readonly _breakpointObserver: BreakpointObserver) {
    this._breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((result: BreakpointState) => {
        this._isMobileSubject.next(result.matches);
      });

      // 1260px
    this._breakpointObserver
      .observe(['(max-width: 1024px)'])
      .subscribe((result: BreakpointState) => {
        this._isTabletSubject.next(result.matches);
      });
  }

  get isMobile(): boolean {
    return this._isMobileSubject.value;
  }
}
