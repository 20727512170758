import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  selector: 'app-telegram-btn',
  standalone: true,
  imports: [CommonModule, NzButtonModule],
  templateUrl: './telegram-btn.component.html',
  styleUrl: './telegram-btn.component.scss',
})
export class TelegramBtnComponent {}
