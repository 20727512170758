import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-goal-panel',
  templateUrl: './goal-panel.component.html',
  styleUrls: ['./goal-panel.component.scss'],
})
export class GoalPanelComponent {
  constructor(private _translateService: TranslateService) {}

  widget = {
    title: this._translateService.instant('goalPanel.widgetTitle'),
    value: 12,
    showPercentages: false,
    range: [120575, 1000000],
    color: '#E78B2D',
  };
}
