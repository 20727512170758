import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonComponent } from 'ng-zorro-antd/button';

@Component({
  selector: 'app-about-project',
  standalone: true,
  imports: [NzButtonComponent, TranslateModule],
  templateUrl: './about-project.component.html',
  styleUrl: './about-project.component.scss',
})
export class AboutProjectComponent {
  @Output() onClose = new EventEmitter();

  public close() {
    this.onClose.emit();
  }
}
