<div class="object-type-filters">
  <h6>{{ "objectTypeFilters.title" | translate }}</h6>
  <nz-list>
    <ng-container
      *ngFor="let objectTypeFilter of objectTypeFilters; let i = index"
    >
      <app-object-type-filter
        [label]="objectTypeFilter.label"
        [iconSrc]="objectTypeFilter.iconSrc"
        [value]="objectTypeFilter.value"
        (valueChanged)="change($event, i)"
        [destroyed]="objectTypeFilter.destroyed"
        [total]="objectTypeFilter.total"
        [percentages]="objectTypeFilter.percentages"
      ></app-object-type-filter>
    </ng-container>
  </nz-list>
</div>
