import { Injectable } from '@angular/core';
import { MilitaryService } from '../military';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  forkJoin,
  switchMap,
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IDamageTypeFilter,
  IMarker,
  IObjectParams,
  IObjectTypeFilter,
} from '../../models';
import { MarkerFilterService } from '../marker-filter';
import { i18nObjectTypeTitleMapping } from '../../helpers';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MarkersService {
  private _subscription!: Subscription;

  private _markersSubject = new BehaviorSubject<IMarker[]>([]);

  public markers$: Observable<IMarker[]> = this._markersSubject.asObservable();

  public allChecked$: Observable<boolean>;
  public damageTypeFilters$: Observable<IDamageTypeFilter[]>;
  public objectTypeFilters$: Observable<IObjectTypeFilter[]>;

  get markers(): IMarker[] {
    return this._markersSubject.value;
  }

  set markers(value: IMarker[] | undefined) {
    this._markersSubject.next(value || []);
  }

  private _getMarkers() {
    return combineLatest([
      this._markerFilterService.objectTypeFilters$,
      this._markerFilterService.damageTypeFilters$,
    ])
      .pipe(
        switchMap(([objectsFilters, damageTypeFilters]) => {
          const params: IObjectParams = {
            page: 1,
            pageSize: 1000,
          };

          const damageTypes = damageTypeFilters
            .filter(({ value }) => value)
            .map(({ id }) => id)
            .join(',');

          if (damageTypes) {
            params.damageTypes = damageTypes;
          }

          const types = objectsFilters
            .filter(({ value }) => value)
            .map(({ _id }) => _id)
            .join(',');
          if (types) {
            params.types = types;
          }

          return forkJoin([
            this._militaryService.getTypes(),
            this._militaryService.getObjects({ params }),
          ]);
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: ([types, objects]) => {
          const markers: IMarker[] = objects.map((object) => {
            const typeInfo = types.find((type) => type._id === object.type);

            const name =
              object.title in i18nObjectTypeTitleMapping
                ? i18nObjectTypeTitleMapping[object.title]
                : object.title;

            const marker: IMarker = {
              ...object,
              latLng: {
                lat: object.latitude,
                lng: object.longitude,
              },
              data: {
                type: object.type,
                currentHp: object.health,
                description: object.description,
                name,
                id: object._id,
                health: typeInfo?.health || 0,
                damagedPicture: typeInfo?.damagedPicture,
                destroyedPicture: typeInfo?.destroyedPicture,
                healthyPicture: typeInfo?.healthyPicture,
                lockedPicture: typeInfo?.lockedPicture,
                state: object?.state,
                isVip: object.isVip,
              },
            };

            return marker;
          });
          
          this.markers = markers;
        },
      });
  }

  constructor(
    private readonly _militaryService: MilitaryService,
    private readonly _markerFilterService: MarkerFilterService
  ) {
    this.allChecked$ = this._markerFilterService.allChecked$;

    this.damageTypeFilters$ = this._markerFilterService.damageTypeFilters$;
    this.objectTypeFilters$ = this._markerFilterService.objectTypeFilters$;

    this._getMarkers();
  }

  // Make sure to unsubscribe when the service is no longer needed
  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
