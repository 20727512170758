import { Directive, inject } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export class ResponsiveComponent {
  private breakpointObserver = inject(BreakpointObserver);
  public touchDeviceMode = false;
  public tabletMode = false;
  public mobileMode = false;

  constructor() {
    this.breakpointObserver
      .observe(['(max-width: 1024px)'])
      .pipe(untilDestroyed(this))
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.touchDeviceMode = true;
        } else {
          this.touchDeviceMode = false;
        }
      });

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .pipe(untilDestroyed(this))
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.tabletMode = true;
        } else {
          this.tabletMode = false;
        }
      });

    this.breakpointObserver
      .observe(['(max-width: 576px)'])
      .pipe(untilDestroyed(this))
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.mobileMode = true;
        } else {
          this.mobileMode = false;
        }
      });
  }
}
