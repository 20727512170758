import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  selector: 'app-about-found',
  standalone: true,
  imports: [NzButtonModule, TranslateModule],
  templateUrl: './about-found.component.html',
  styleUrl: './about-found.component.scss',
})
export class AboutFoundComponent {
  @Output() onClose = new EventEmitter();

  public close() {
    this.onClose.emit();
  }
}
