<ng-template #notif let-notif="data">
  <div class="notification">
    <div class="notification__info">
      <div
        class="notification__icon flex-center"
        [style]="{ background: '#E7EDEE' }"
      >
        <img src="/assets/avatars/molotov-cocktail-1.svg" alt="Avatar" />
      </div>
      <div class="notification__content">
        <h6
          class="notification__title"
          nz-typography
          nzEllipsis
          [nzEllipsisRows]="2"
        >
          {{ notif?.username ?? "Anonymous" }}
        </h6>
        <div
          class="notification__message"
          nz-typography
          nzEllipsis
          [nzEllipsisRows]="3"
        >
          {{ notif?.message }}
        </div>
      </div>
    </div>
    <strong class="notification__donate">
      {{ notif?.amount }}&nbsp;{{ notif?.currencySymbol }}
    </strong>
  </div>
</ng-template>
