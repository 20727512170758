import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IMarkerData } from '../../models';
import { calculatePercentage, generateIcon } from '../../helpers';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NumberSpacePipe } from '../../pipes';

@Component({
  selector: 'app-facility-widget',
  standalone: true,
  imports: [CommonModule, NzProgressModule, NumberSpacePipe],
  templateUrl: './facility-widget.component.html',
  styleUrls: ['./facility-widget.component.scss']
})
export class FacilityWidgetComponent {
  @Input() facility!: IMarkerData;
  public damage = 0;
  @Input() set damagePreview(value: number) {
    this.damage = value;
  }

  get persentage(): number {
    const {health, currentHp} = this.facility;
    return calculatePercentage(health - currentHp, health);
  }

  get persentagePreview(): number {
    const {health, currentHp} = this.facility;
    return calculatePercentage((health + this.damage ) - (currentHp), health);
  }

  get damaged(): number {
    const {health, currentHp} = this.facility;
    return health - currentHp;
  }

  public generateIcon = generateIcon;
  public calculatePercentage = calculatePercentage;
}
