import { Injectable } from '@angular/core';
import { IDamageTypeFilter } from '../../models';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { ObjectState } from '../../enums';

@Injectable({
  providedIn: 'root',
})
export class DamageTypeFilterService {
  private _damageTypeFiltersSubject = new BehaviorSubject<IDamageTypeFilter[]>([
    {
      id: ObjectState.FULL,
      label: 'objectState.full',
      value: true,
    },
    {
      id: ObjectState.DAMAGED,
      label: 'objectState.damaged',
      value: true,
    },
    {
      id: ObjectState.DESTROYED,
      label: 'objectState.destroyed',
      value: true,
    },
    {
      id: ObjectState.LOCKED,
      label: 'objectState.locked',
      value: true,
    },
  ]);
  private _getAllChecked = (
    damageTypeFilters: IDamageTypeFilter[]
  ): boolean => {
    return damageTypeFilters.every(({ value }) => value);
  };
  private _getIsSomeChecked = (
    damageTypeFilters: IDamageTypeFilter[]
  ): boolean => {
    return damageTypeFilters.some(({ value }) => value);
  };

  public damageTypeFilters$: Observable<IDamageTypeFilter[]> =
    this._damageTypeFiltersSubject.asObservable();
  public allChecked$: Observable<boolean> = combineLatest([
    this.damageTypeFilters$,
  ]).pipe(
    map(([damageTypeFilters]) => {
      return this._getAllChecked(damageTypeFilters);
    })
  );

  get allChecked(): boolean {
    return this._getAllChecked(this.damageTypeFilters);
  }

  public change(value: boolean, index: number): void {
    const target: IDamageTypeFilter = {
      ...this.damageTypeFilters[index],
      value,
    };

    const damageTypeFilters = [
      ...this.damageTypeFilters.slice(0, index),
      target,
      ...this.damageTypeFilters.slice(index + 1),
    ];

    this.damageTypeFilters = damageTypeFilters;
  }

  public changeAll(value: boolean): void {
    this.damageTypeFilters = this.damageTypeFilters.map((filter) => ({
      ...filter,
      value,
    }));
  }

  get damageTypeFilters(): IDamageTypeFilter[] {
    return this._damageTypeFiltersSubject.value;
  }

  set damageTypeFilters(value: IDamageTypeFilter[]) {
    this._damageTypeFiltersSubject.next(value);
  }

  get isSomeChecked(): boolean {
    return this._getIsSomeChecked(this.damageTypeFilters);
  }
}
