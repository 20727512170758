import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NzListModule } from 'ng-zorro-antd/list';
import { ObjectTypeFilterComponent } from './components/object-type-filter/object-type-filter.component';
import { IObjectTypeFilter } from 'src/app/shared/models';
import { MarkerFilterService } from 'src/app/shared/services';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-object-type-filters',
  templateUrl: './object-type-filters.component.html',
  styleUrls: ['./object-type-filters.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NzListModule,
    ObjectTypeFilterComponent,
    TranslateModule,
  ],
})
export class ObjectTypeFiltersComponent {
  public objectTypeFilters: IObjectTypeFilter[] = [];

  constructor(private readonly _markerFilterService: MarkerFilterService) {
    this._markerFilterService.objectTypeFilters$.subscribe(
      (objectTypeFilters) => {
        this.objectTypeFilters = objectTypeFilters;
      }
    );
  }

  change(value: boolean, index: number): void {
    this._markerFilterService.changeObjectTypeFilter(value, index);
  }
}
