import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IListResponse } from 'src/app/core/interfaces';
import { ITransaction } from '../../models';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private readonly _http: HttpClient = inject(HttpClient);

  private _donateTransaction = new Subject<void>();
  public donateTransaction$ = this._donateTransaction.asObservable();

  getAll(params: any): Observable<IListResponse<ITransaction>> {
    return this._http.get<IListResponse<ITransaction>>(
      `${environment.apiGateway}/v1/events/objects`,
      { params }
    );
  }

  lastActivity(count = 15): Observable<any> {
    return this._http.get<any>(`${environment.apiGateway}/v1/events/last-activity`, {params: {count}});
  }

  getObjectTransactions(
    id: string,
    params: any
  ): Observable<any> {
    return this._http.get<IListResponse<ITransaction>>(
      `${environment.apiGateway}/v1/events/objects/${id}`,
      { params }
    );
  }

  noEffortDonate(): void {
    this._donateTransaction.next();
  }
}
