<div class="project-profile" (click)="open()">
  <div class="project-profile__info">
    <div class="project-profile__info-item">
      <div class="project-profile__info-item-icon">
        <img
          src="/assets/avatars/molotov-cocktail-2.svg"
          width="32"
          height="32"
          alt="{{ 'projectProfile.registration' | translate }}"
        />
      </div>
      <h6 class="project-profile__info-item-title">
        {{ "projectProfile.registration" | translate }}
      </h6>
    </div>
    <div class="project-profile__info-item">
      <div class="project-profile__info-item-icon">
        <img
          src="/assets/icons/cup-reward.svg"
          width="32"
          height="32"
          alt="{{ 'projectProfile.awards' | translate }}"
        />
      </div>
      <h6 class="project-profile__info-item-title">
        {{ "projectProfile.awards" | translate }}
      </h6>
    </div>
  </div>
  <div class="project-profile__soon">
    <img
      src="/assets/icons/clock.svg"
      class=""
      width="34"
      height="34"
      alt="Soon"
    />
    <h5>{{ "projectProfile.soon" | translate }}</h5>
    <p [innerHTML]="'projectProfile.soonSecondary' | translate"></p>
  </div>
</div>
