<ng-container *ngIf="!isMobile; else mobile">
  <div class="btn-wrapper" [ngClass]="{ active: isVisible }">
    <button
      nz-button
      nzShape="round"
      class="language-selector-btn"
      [ngClass]="{ active: isVisible }"
      [(nzPopoverVisible)]="isVisible"
      (nzPopoverVisibleChange)="change($event)"
      [nzPopoverPlacement]="placement"
      nzPopoverTrigger="click"
      nz-popover
      [nzPopoverTitle]="undefined"
      [nzPopoverContent]="languageSelector"
      nzPopoverOverlayClassName="language-selector-popover"
    >
      <img
        [src]="selectedLanguage.icon"
        width="28"
        height="28"
        [alt]="selectedLanguage.label"
      />
    </button>

    <div class="over" (click)="close()"></div>
  </div>
</ng-container>

<ng-template #mobile>
  <button
    nz-button
    nzShape="round"
    class="language-selector-btn"
    [ngClass]="{ active: isVisible }"
    (click)="open()"
  >
    <img
      [src]="selectedLanguage.icon"
      width="28"
      height="28"
      [alt]="selectedLanguage.label"
    />
  </button>
  <nz-drawer
    [(nzVisible)]="isVisible"
    [nzContent]="languageSelector"
    [nzClosable]="false"
    nzWrapClassName="language-selector-drawer"
    nzHeight="auto"
    (nzOnClose)="close()"
    [nzTitle]="undefined"
    nzPlacement="bottom"
  >
  </nz-drawer>
</ng-template>
<ng-template #languageSelector [nzModalContent]>
  <ul class="language-selector">
    <li
      class="language-selector__item"
      *ngFor="let language of listOfLanguages"
    >
      <button
        type="button"
        class="language-selector__item-button"
        (click)="selectLanguage(language.value)"
      >
        <img
          [src]="language.icon"
          width="24"
          height="24"
          [alt]="language.label"
          class="language-selector__item-button-icon"
        />
        <span class="language-selector__item-button-label">
          {{ language.label }}
        </span>
      </button>
    </li>
  </ul>
  <div class="footer">
    <button class="footer-arrow" (click)="close()">
      <img
        src="/assets/icons/arrow-down.svg"
        width="18"
        height="18"
        alt="Arrow"
      />
    </button>
  </div>
</ng-template>
