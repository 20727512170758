import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const SOUND_STORAGE_KEY = 'sound_on';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  private readonly _initialIsSoundOn: boolean = true;
  private _isSoundOnSubject: BehaviorSubject<boolean>;

  public _isSoundOn$: Observable<boolean>;

  constructor() {
    // Load the sound state from local storage
    const storedValue = localStorage.getItem(SOUND_STORAGE_KEY);
    const parsedValue = storedValue
      ? JSON.parse(storedValue)
      : this._initialIsSoundOn;
    const processedValue =
      typeof parsedValue === 'boolean' ? parsedValue : this._initialIsSoundOn;

    this._isSoundOnSubject = new BehaviorSubject(processedValue);
    this._isSoundOn$ = this._isSoundOnSubject.asObservable();
  }

  get isSoundOn(): boolean {
    return this._isSoundOnSubject.value;
  }

  set isSoundOn(value: boolean) {
    this._isSoundOnSubject.next(value);
    localStorage.setItem(SOUND_STORAGE_KEY, JSON.stringify(value));
  }
}
