import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SwitchComponent } from '../../../../../switch/switch.component';
import { isFiniteNumber } from 'src/app/shared/helpers';
import { DamageRowComponent } from './components/damage-row/damage-row.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-object-type-filter',
  templateUrl: './object-type-filter.component.html',
  styleUrls: ['./object-type-filter.component.scss'],
  imports: [SwitchComponent, DamageRowComponent, TranslateModule],
  standalone: true,
})
export class ObjectTypeFilterComponent {
  @Input() destroyed!: number;
  @Input() iconSrc!: string;
  @Input() label!: string;
  @Input() percentages!: number;
  @Input() total!: number;
  @Input() value!: boolean;
  @Output() valueChanged = new EventEmitter<boolean>();

  get destroyedPercentage(): string {
    let percentage = 0;

    if (isFiniteNumber(this.destroyed) && isFiniteNumber(this.total)) {
      percentage = Math.floor(this.percentages);
    }

    return `${percentage}%`;
  }

  toggleSwitch() {
    this.value = !this.value;
    this.valueChanged.emit(this.value);
  }
}
