import { Component, Input } from '@angular/core';
import { Currency } from '../../enums';
import { getCurrencySymbol } from '../../helpers';

interface IDamageRowData {
  amount: number;
  currency: Currency;
  message: string;
  username: string;
}

@Component({
  selector: 'app-damage-row',
  templateUrl: './damage-row.component.html',
  styleUrls: ['./damage-row.component.scss'],
})
export class DamageRowComponent {
  @Input({ required: true }) transaction!: IDamageRowData;

  get currencySymbol(): string {
    return getCurrencySymbol(this.transaction.currency);
  }
}
