import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { DamageRowModule } from '../damage-row';
import {
  ILoadingHandler,
  LoadingHandlerToken,
  ParamsHandler,
  ResponsiveComponent,
  provideLoadingHandler,
} from '../../classes';
import { IHttpParams } from 'src/app/core/interfaces';
import {
  RealtimeService,
  ScreenResolutionService,
  TransactionService,
} from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { ITransaction } from '../../models';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GAEvent, GAEventQueueService, GAEventType } from 'src/app/ga-events';

@UntilDestroy()
@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    DamageRowModule,
    NzModalModule,
    NzDrawerModule,
    NzListModule,
    NzPopoverModule,
    InfiniteScrollModule,
    TranslateModule,
  ],
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [provideLoadingHandler()],
})
export class ChatComponent extends ResponsiveComponent implements OnInit {
  public isVisible: boolean = false;
  public total = 0;
  public transactions: ITransaction[] = [];
  public loadingMore = false;
  public isMobileChatVisible = false;
  public isMobile = false;

  public paramsHandler = new ParamsHandler<any>({
    page: 1,
    pageSize: 50,
  });

  get paginationParams(): IHttpParams {
    return this.paramsHandler.params;
  }

  private readonly _transactionsService = inject(TransactionService);
  private _realtimeService = inject(RealtimeService);
  private _screenResolutionService = inject(ScreenResolutionService);
  private _eventQueue = inject(GAEventQueueService);

  constructor(
    @Inject(LoadingHandlerToken) public loadingHandler: ILoadingHandler
  ) {
    super();
  }

  public ngOnInit(): void {
    this._getTransactions();

    this._screenResolutionService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    this._realtimeService
      .onFetchDamage()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          // @ts-ignore
          this.transactions.unshift(data);
        },
      });
  }

  public close(): void {
    this.isVisible = false;
  }

  public change(state: boolean): void {
    if (state) {
      this._eventQueue.dispatch(new GAEvent(GAEventType.CHAT_CLICK, state));
    }
  }

  public onScroll(): void {
    if (this.canLoadMore()) {
      this.onLoadMore();
    }
  }

  public canLoadMore(): boolean {
    return this.paginationParams.page !== this.calculatePageCount();
  }

  public calculatePageCount() {
    return Math.ceil(this.total / this.paginationParams.pageSize);
  }

  public onLoadMore(): void {
    this.paramsHandler.patchParams({
      page: this.paginationParams.page + 1,
    });

    this._transactionsService
      .getAll(this.paginationParams)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: any) => {
          this.transactions = [...this.transactions, ...response.data];
          this.total = response.total;
        },
      });
  }

  public openMobileChat(): void {
    this.isMobileChatVisible = true;
    this._eventQueue.dispatch(new GAEvent(GAEventType.CHAT_CLICK, true));
  }

  public closeMobileChat(): void {
    this.isMobileChatVisible = false;
  }

  public donate(): void {
    this.closeMobileChat();
    this.close();
    this._transactionsService.noEffortDonate();
  }

  private _getTransactions(): void {
    const hide = this.loadingHandler.showLoading();
    this._transactionsService
      .getAll(this.paginationParams)
      .pipe(finalize(hide), untilDestroyed(this))
      .subscribe({
        next: (response: any) => {
          this.transactions = response.data;
          this.total = response.total;
        },
      });
  }
}
