import { Injectable } from '@angular/core';
import { DamageTypeFilterService } from '../damage-type-filter';
import { ObjectTypeFilterService } from '../object-type-filter';
import { IDamageTypeFilter, IObjectTypeFilter } from '../../models';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarkerFilterService {
  private _allCheckedSubject = new BehaviorSubject<boolean>(true);

  public allChecked$: Observable<boolean> =
    this._allCheckedSubject.asObservable();

  constructor(
    private readonly _damageTypeFilterService: DamageTypeFilterService,
    private readonly _objectTypeFilterService: ObjectTypeFilterService
  ) {
    combineLatest([
      this._objectTypeFilterService.allChecked$,
      this._damageTypeFilterService.allChecked$,
    ])
      .pipe(
        map(([allObjectsFiltersChecked, allDamageTypeFiltersChecked]) => {
          return allObjectsFiltersChecked && allDamageTypeFiltersChecked;
        })
      )
      .subscribe({
        next: (allChecked) => {
          this.allChecked = allChecked;
        },
      });
  }

  public changeAll(isChecked: boolean) {
    this._damageTypeFilterService.changeAll(isChecked);

    this._objectTypeFilterService.changeAll(isChecked);

    this.allChecked = isChecked;
  }

  public changeObjectTypeFilter(checked: boolean, index: number): void {
    this._objectTypeFilterService.change(checked, index);

    if (
      (this.isSomeObjectTypeFilterChecked || !checked) &&
      !this.isSomeDamageTypeFilterChecked
    ) {
      this._damageTypeFilterService.changeAll(true);
    }
  }

  public changeDamageTypeFilter(value: boolean, index: number): void {
    return this._damageTypeFilterService.change(value, index);
  }

  get allChecked(): boolean {
    return this._allCheckedSubject.value;
  }

  set allChecked(value: boolean) {
    this._allCheckedSubject.next(value);
  }

  get isSomeObjectTypeFilterChecked(): boolean {
    return this._objectTypeFilterService.isSomeChecked;
  }

  get isSomeDamageTypeFilterChecked(): boolean {
    return this._damageTypeFilterService.isSomeChecked;
  }

  get objectTypeFilters$(): Observable<IObjectTypeFilter[]> {
    return this._objectTypeFilterService.objectTypeFilters$;
  }

  get damageTypeFilters$(): Observable<IDamageTypeFilter[]> {
    return this._damageTypeFilterService.damageTypeFilters$;
  }
}
