import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import {
  NzNotificationModule,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { ParamsHandler } from '../../classes';
import { IHttpParams } from 'src/app/core/interfaces';
import { RealtimeService, TransactionService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { Currency } from '../../enums';
import { getCurrencySymbol } from '../../helpers';

interface INotificationData {
  _id?: string;
  id?: string;
  amount: number;
  currency: Currency;
  message: string;
  username: string;
}

@UntilDestroy()
@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [CommonModule, NzNotificationModule, NzTypographyModule],
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements AfterViewInit, OnInit {
  @ViewChild('notif', { static: false }) notif?: TemplateRef<{}>;

  public paramsHandler = new ParamsHandler<IHttpParams>({
    page: 1,
    pageSize: 3,
  });

  get paginationParams(): IHttpParams {
    return this.paramsHandler.params;
  }

  private readonly _transactionsService = inject(TransactionService);
  private _notification = inject(NzNotificationService);
  private _realtimeService = inject(RealtimeService);

  public ngOnInit(): void {
    this._realtimeService
      .onFetchDamage()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          this._createBasicNotification(data);
        },
      });
  }

  public ngAfterViewInit(): void {
    this._getTransactions();
  }

  private _createBasicNotification(data: INotificationData): void {
    const currencySymbol = getCurrencySymbol(data.currency);

    this._notification.template(this.notif!, {
      nzDuration: 3500,
      nzPlacement: 'bottomLeft',
      nzStyle: {
        zIndex: 0,
      },
      nzData: { ...data, currencySymbol },
      nzKey: data?.id ?? data?._id,
    });
  }

  private _getTransactions(): void {
    this._transactionsService
      .getAll(this.paginationParams)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          const { data } = response;
          let pointer = 2;

          let interval = setInterval(() => {
            const transaction = data[pointer];
            if (data[pointer]) {
              this._createBasicNotification(transaction);
            }

            if (pointer === 0) {
              clearInterval(interval);
            }

            pointer--;
          }, 1000);
        },
      });
  }
}
