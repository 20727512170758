import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CountUpModule } from 'ngx-countup';

@UntilDestroy(this)
@Component({
  selector: 'app-collected',
  standalone: true,
  imports: [CommonModule, TranslateModule, CountUpModule],
  templateUrl: './collected.component.html',
  styleUrls: ['./collected.component.scss'],
  host: {
    style: 'width: 100%;', // Apply styles directly to the host element
  },
})
export class CollectedComponent {
  @Input({ required: true }) public revenue = 0;
  @Input({ required: true }) public goal = 0;

  public countUpOptions = {
    startVal: 0,
    duration: 2,
    useEasing: true,
    separator: ' ',
  };
}
