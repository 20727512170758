import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { LinkedinComponent } from '../linkedin/linkedin.component';

@Component({
  selector: 'app-qa',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonComponent,
    TranslateModule,
    LinkedinComponent,
  ],
  templateUrl: './qa.component.html',
  styleUrl: './qa.component.scss',
})
export class QaComponent {
  @Output() onClose = new EventEmitter();

  public items: number[] = Array.from({ length: 7 }, (_, index) => index);

  public close() {
    this.onClose.emit();
  }
}
