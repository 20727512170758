import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { IObject, IType } from '../../models';
import { environment as env } from 'src/environment/environment';
import { IHttpParams, IListResponse } from 'src/app/core/interfaces';
import { IObjectParams } from '../../models/object-params.interface';

@Injectable({
  providedIn: 'root',
})
export class MilitaryService {
  constructor(private _http: HttpClient) {}

  getObjects({ params }: { params: IObjectParams }): Observable<IObject[]> {
    if (!params.damageTypes || !params.types) {
      return of([]);
    }

    return this._http
      .get<IListResponse<IObject>>(
        `${env.apiGateway}/v1/map/military/objects`,
        {
          // @ts-ignore
          params,
        }
      )
      .pipe(
        // @ts-ignore
        map((response: IListResponse<IObject>) => {
          return response.data;
        }),
        catchError(() => {
          // Return an empty array as a fallback
          return of([]);
        })
      );
  }

  getTypes(): Observable<IType[]> {
    const params = {
      page: 1,
      pageSize: 100,
    } satisfies IHttpParams;

    return this._http
      .get<IListResponse<IType>>(`${env.apiGateway}/v1/map/military/types`, {
        params,
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }
}
