import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SwitchComponent } from '../../../switch/switch.component';
import { MarkerFilterService } from 'src/app/shared/services';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-all-filter',
  templateUrl: './all-filter.component.html',
  styleUrls: ['./all-filter.component.scss'],
  standalone: true,
  imports: [CommonModule, SwitchComponent, TranslateModule],
})
export class AllFilterComponent {
  public allShown: boolean = true;

  constructor(private readonly _markerFilterService: MarkerFilterService) {
    this._markerFilterService.allChecked$.subscribe((allShown) => {
      this.allShown = allShown;
    });
  }

  change(value: boolean): void {
    this._markerFilterService.changeAll(value);
  }
}
